// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* LoadingSpinner.css */

.loading-spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-color: var(--light-background-color);
}

.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--primary-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.loading-text {
  margin-top: 16px;
  font-size: 18px;
  color: var(--light-text-color);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 768px) {
  .loading-spinner {
    width: 32px;
    height: 32px;
  }

  .loading-text {
    font-size: 16px;
  }
}

@media (prefers-color-scheme: dark) {
  .loading-spinner-container {
    background-color: var(--dark-background-color);
  }

  .loading-spinner {
    border-color: rgba(255, 255, 255, 0.1);
    border-left-color: var(--primary-color);
  }

  .loading-text {
    color: var(--dark-text-color);
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/LoadingSpinner.css"],"names":[],"mappings":"AAAA,uBAAuB;;AAEvB;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,WAAW;EACX,+CAA+C;AACjD;;AAEA;EACE,oCAAoC;EACpC,uCAAuC;EACvC,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;AACpC;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,8BAA8B;AAChC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE;IACE,WAAW;IACX,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;AACF;;AAEA;EACE;IACE,8CAA8C;EAChD;;EAEA;IACE,sCAAsC;IACtC,uCAAuC;EACzC;;EAEA;IACE,6BAA6B;EAC/B;AACF","sourcesContent":["/* LoadingSpinner.css */\n\n.loading-spinner-container {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  width: 100%;\n  background-color: var(--light-background-color);\n}\n\n.loading-spinner {\n  border: 4px solid rgba(0, 0, 0, 0.1);\n  border-left-color: var(--primary-color);\n  border-radius: 50%;\n  width: 40px;\n  height: 40px;\n  animation: spin 1s linear infinite;\n}\n\n.loading-text {\n  margin-top: 16px;\n  font-size: 18px;\n  color: var(--light-text-color);\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n\n@media (max-width: 768px) {\n  .loading-spinner {\n    width: 32px;\n    height: 32px;\n  }\n\n  .loading-text {\n    font-size: 16px;\n  }\n}\n\n@media (prefers-color-scheme: dark) {\n  .loading-spinner-container {\n    background-color: var(--dark-background-color);\n  }\n\n  .loading-spinner {\n    border-color: rgba(255, 255, 255, 0.1);\n    border-left-color: var(--primary-color);\n  }\n\n  .loading-text {\n    color: var(--dark-text-color);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
