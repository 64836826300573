import * as React from "react";
import { Button as AntButton, ButtonProps as AntButtonProps } from "antd";
import { cn } from "../../lib/utils";
import "../../styles/button.css";

type Variant =
  | "default"
  | "destructive"
  | "outline"
  | "secondary"
  | "ghost"
  | "link";
type Size = "default" | "sm" | "lg" | "icon";

interface ButtonProps extends Omit<AntButtonProps, "size" | "type"> {
  variant?: Variant;
  size?: Size;
}

const variantMapping: Record<Variant, AntButtonProps["type"]> = {
  default: "primary",
  destructive: "primary",
  outline: "default",
  secondary: "default",
  ghost: "default",
  link: "link",
};

const sizeMapping: Record<Size, AntButtonProps["size"]> = {
  default: "middle",
  sm: "small",
  lg: "large",
  icon: "small",
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant = "default", size = "default", className, ...props }, ref) => {
    // Determine Ant Design's Button type based on the variant
    const antButtonType = variantMapping[variant];

    // Determine Ant Design's Button size based on the size prop
    const antButtonSize = sizeMapping[size];

    // Handle special cases for variants that require additional props
    const additionalProps: AntButtonProps = {
      ...props,
      danger: variant === "destructive",
      ghost: variant === "ghost",
    };

    if (variant === "outline") {
      additionalProps.ghost = true;
      additionalProps.style = {
        ...additionalProps.style,
        border: "1px solid #d9d9d9",
      };
    }

    return (
      <AntButton
        type={antButtonType}
        size={antButtonSize}
        className={cn(`ant-btn-${variant}`, className)}
        ref={ref}
        {...additionalProps}
      />
    );
  }
);

Button.displayName = "Button";

export { Button };
