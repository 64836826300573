import React, { useState, useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { useAuth } from '../AuthContext'
import { callAI } from '../AI'
import {
  saveChatSession,
  loadChatSessions,
  deleteChatSession,
  getCurrentSubscription
} from '../DatabaseService'
import ChatList from './ChatList'
import '../styles/AIChat.css'
import MainChat from './MainChatComponent'
import AntDrawer from './Drawer'
import { useSearchParams, useNavigate } from 'react-router-dom'
import ErrorBoundary from './ErrorBoundary'

const AIChat = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { user } = useAuth()
  
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [selectedLLM, setSelectedLLM] = useState('openai')
  const [defaultModel, setDefaultModel] = useState('gpt-3.5-turbo')
  const [sessions, setSessions] = useState([])
  const [currentSessionId, setCurrentSessionId] = useState(null)
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const newChatInitialized = useRef(false)
  const [currentTitle, setCurrentTitle] = useState('AI Chat')

  useEffect(() => {
    const savedLLM = localStorage.getItem('selectedLLM') || 'openai'
    const savedModel = localStorage.getItem('defaultModel') || 'gpt-3.5-turbo'
    setSelectedLLM(savedLLM)
    setDefaultModel(savedModel)
    loadChatHistory()
  }, [user])

  useEffect(() => {
    const message = searchParams.get('message')
    const newChat = searchParams.get('newChat')
    if (message && newChat === 'true' && !newChatInitialized.current) {
      console.log('Initializing new chat from URL parameters')
      newChatInitialized.current = true
      sendMessage(message, null, true)
    }
  }, [searchParams])

  const loadChatHistory = async () => {
    try {
      const chatSessions = await loadChatSessions(user.id)
      setSessions(chatSessions)
      if (chatSessions.length > 0) {
        setCurrentSessionId(chatSessions[0].session_id)
        setMessages(chatSessions[0].messages)
        setCurrentTitle(chatSessions[0].title)
      }
    } catch (error) {
      console.error('Error loading chat history:', error)
    }
  }

  const selectSession = sessionId => {
    const session = sessions.find(s => s.session_id === sessionId)
    if (session) {
      setCurrentSessionId(sessionId)
      setMessages(session.messages)
      setCurrentTitle(session.title)
      navigate(`/ai-chat?session=${sessionId}`, { replace: true })
    }
  }

  const deleteSession = async sessionId => {
    try {
      await deleteChatSession(sessionId)
      setSessions(sessions.filter(s => s.session_id !== sessionId))
      if (currentSessionId === sessionId) {
        if (sessions.length > 1) {
          const newCurrentSession = sessions.find(s => s.session_id !== sessionId)
          selectSession(newCurrentSession.session_id)
        } else {
          setCurrentSessionId(null)
          setMessages([])
        }
      }
    } catch (error) {
      console.error('Error deleting chat session:', error)
    }
  }

  const generateTitle = async (messages) => {
    try {
      const prompt = `Generate a short, concise title (max 5 words) for this conversation:\n\n${messages
        .map(m => `${m.sender}: ${m.text}`)
        .join('\n')}`
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
      const apiKey = apiKeys[selectedLLM]
      const title = await callAI(prompt, selectedLLM, defaultModel, apiKey)
      return title.trim()
    } catch (error) {
      console.error('Error generating title:', error)
      return 'New Chat'
    }
  }

  const sendMessage = async (messageToSend = input, sessionId = currentSessionId, isNewChat = false) => {
    if (!messageToSend.trim()) return

    console.log('Starting sendMessage', { messageToSend, sessionId, isNewChat });

    setIsLoading(true)
    let updatedMessages = []
    let newSessionId = sessionId

    if (isNewChat || !sessionId) {
      newSessionId = uuidv4()
      updatedMessages = [{ text: messageToSend, sender: 'user' }]
      setCurrentSessionId(newSessionId)
      console.log('Created new session', newSessionId);
    } else {
      updatedMessages = [...messages, { text: messageToSend, sender: 'user' }]
      console.log('Using existing session', sessionId);
    }

    setMessages(updatedMessages)
    setInput('')

    try {
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
      let apiKey = apiKeys[selectedLLM]

      if (!apiKey && !currentSubscription) {
        throw new Error(
          `No API key found for ${selectedLLM}. Please add it in the settings or subscribe to one of the plans`
        )
      }
      if (
        !currentSubscription &&
        apiKey !==
          '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'
      ) {
        throw new Error(
          `API key found for ${selectedLLM} is wrong. Please add correct one in the settings or subscribe to one of the plans`
        )
      }
      if (currentSubscription) {
        apiKey =
          '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'
      }

      console.log('Calling AI');
      const response = await callAI(messageToSend, selectedLLM, defaultModel, apiKey)
      const aiMessage = { text: response, sender: 'ai' }
      const finalMessages = [...updatedMessages, aiMessage]
      setMessages(finalMessages)

      let title = 'New Chat'
      if (isNewChat) {
        console.log('Generating title for new chat');
        title = await generateTitle(finalMessages)
      } else {
        title = sessions.find(s => s.session_id === newSessionId)?.title || 'New Chat'
      }

      setCurrentTitle(title)

      console.log('Saving chat session', { newSessionId, title });
      await saveChatSession(user.id, newSessionId, title, finalMessages)
      
      setSessions(prevSessions => {
        let updatedSessions;
        if (isNewChat) {
          updatedSessions = [{ session_id: newSessionId, title, messages: finalMessages }, ...prevSessions]
        } else {
          updatedSessions = prevSessions.map(s => 
            s.session_id === newSessionId ? { ...s, title, messages: finalMessages } : s
          )
        }
        console.log('Updated sessions:', updatedSessions);
        return updatedSessions;
      })

      // Always update currentSessionId to the new or current session
      setCurrentSessionId(newSessionId)

      if (isNewChat) {
        console.log('Navigating to new chat');
        navigate(`/ai-chat?session=${newSessionId}`, { replace: true })
      }
    } catch (error) {
      console.error('Error sending message:', error)
      setMessages(prevMessages => [
        ...prevMessages,
        { text: `Error: ${error.message}`, sender: 'ai' }
      ])
    } finally {
      setIsLoading(false)
      if (isNewChat) {
        newChatInitialized.current = false
      }
    }
  }

  const getCurrentUserSubscription = async user => {
    try {
      const result = await getCurrentSubscription(user.id)
      setCurrentSubscription(result)
    } catch (error) {
      console.error('Error fetching subscription:', error)
    }
  }

  useEffect(() => {
    if (user) {
      getCurrentUserSubscription(user)
    }
  }, [user])

  return (
    <ErrorBoundary>
      <div className='ai-chat-container'>
        <div className='chat-screen-container'>
          <div className='chat-header'>
            <div className='ai-name'>
              {currentTitle}
            </div>
            <AntDrawer onAddNew={() => sendMessage('', null, true)}>
              <ChatList
                sessions={sessions}
                onSelectSession={selectSession}
                onDeleteSession={deleteSession}
                currentSessionId={currentSessionId}
                onNewSession={() => sendMessage('', null, true)}
              />
            </AntDrawer>
          </div>
          <ErrorBoundary>
            <MainChat
              messages={messages}
              input={input}
              setInput={setInput}
              sendMessage={sendMessage}
              isLoading={isLoading}
            />
          </ErrorBoundary>
        </div>
      </div>
    </ErrorBoundary>
  )
}

export default AIChat
