import React, { useState } from 'react';
import { Input, Select, Switch, Button } from 'antd';

const ElevenLabsSettings = ({ onSave }) => {
  const [apiKey, setApiKey] = useState('');
  const [selectedVoice, setSelectedVoice] = useState('');
  const [readAloud, setReadAloud] = useState(false);

  const handleSave = () => {
    onSave({ apiKey, selectedVoice, readAloud });
  };

  return (
    <div>
      <h3>ElevenLabs Settings</h3>
      <Input
        placeholder="Enter ElevenLabs API Key"
        value={apiKey}
        onChange={(e) => setApiKey(e.target.value)}
      />
      <Select
        placeholder="Select Voice"
        value={selectedVoice}
        onChange={setSelectedVoice}
        style={{ width: '100%', marginTop: 10 }}
      >
        {/* Add voice options here */}
        <Select.Option value="voice1">Voice 1</Select.Option>
        <Select.Option value="voice2">Voice 2</Select.Option>
      </Select>
      <Switch
        checked={readAloud}
        onChange={setReadAloud}
        checkedChildren="Read Aloud On"
        unCheckedChildren="Read Aloud Off"
        style={{ marginTop: 10 }}
      />
      <Button type="primary" onClick={handleSave} style={{ marginTop: 10 }}>
        Save Settings
      </Button>
    </div>
  );
};

export default ElevenLabsSettings;