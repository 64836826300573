import React, { useState, useEffect } from 'react'
import { Input, Select, Switch, Button } from 'antd'
import '../styles/Settings.css'

const Settings = () => {
  const [selectedLLM, setSelectedLLM] = useState('openai')
  const [defaultModel, setDefaultModel] = useState('gpt-4o-mini')
  const [apiKeys, setApiKeys] = useState({
    openai: '',
    claude: '',
    gemini: '',
    mixtral: '',
    ollama: ''
  })
  const [elevenlabsApiKey, setElevenlabsApiKey] = useState('')
  const [selectedVoice, setSelectedVoice] = useState('')
  const [readAloud, setReadAloud] = useState(false)

  useEffect(() => {
    const savedLLM = localStorage.getItem('selectedLLM') || 'openai'
    const savedModel = localStorage.getItem('defaultModel') || 'gpt-3.5-turbo'
    const savedApiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
    const savedElevenlabsApiKey = localStorage.getItem('elevenlabsApiKey') || ''
    const savedSelectedVoice = localStorage.getItem('selectedVoice') || ''
    const savedReadAloud = localStorage.getItem('readAloud') === 'true'

    setSelectedLLM(savedLLM)
    setDefaultModel(savedModel)
    setApiKeys(prevKeys => ({ ...prevKeys, ...savedApiKeys }))
    setElevenlabsApiKey(savedElevenlabsApiKey)
    setSelectedVoice(savedSelectedVoice)
    setReadAloud(savedReadAloud)
  }, [])

  const handleLLMChange = value => {
    setSelectedLLM(value)
    localStorage.setItem('selectedLLM', value)
  }

  const handleModelChange = value => {
    setDefaultModel(value)
    localStorage.setItem('defaultModel', value)
  }

  const handleApiKeyChange = (llm, value) => {
    const newApiKeys = { ...apiKeys, [llm]: value }
    setApiKeys(newApiKeys)
    localStorage.setItem('apiKeys', JSON.stringify(newApiKeys))
  }

  const handleSave = () => {
    localStorage.setItem('elevenlabsApiKey', elevenlabsApiKey)
    localStorage.setItem('selectedVoice', selectedVoice)
    localStorage.setItem('readAloud', readAloud.toString())
  }

  const getModelOptions = () => {
    const options = [
      { value: 'gpt-4o', label: 'GPT-4o - High-intelligence flagship model' },
      {
        value: 'gpt-4o-mini',
        label: 'GPT-4o mini - Affordable and intelligent small model'
      },
      { value: 'o1-preview', label: 'o1-preview - Complex reasoning model' },
      { value: 'o1-mini', label: 'o1-mini - Complex reasoning model' },
      {
        value: 'gpt-4-turbo',
        label: 'GPT-4 Turbo - Previous high-intelligence model'
      },
      { value: 'gpt-4', label: 'GPT-4 - Previous high-intelligence model' },
      {
        value: 'gpt-3.5-turbo',
        label: 'GPT-3.5 Turbo - Fast, inexpensive model for simple tasks'
      },
      { value: 'dall-e', label: 'DALL·E - Image generation and editing model' },
      { value: 'tts', label: 'TTS - Text-to-speech model' },
      { value: 'whisper', label: 'Whisper - Speech-to-text model' }
    ]
    return selectedLLM === 'openai'
      ? options
      : [{ value: defaultModel, label: defaultModel }]
  }

  return (
    <div className='settings-container'>
      <h1 style={{ fontSize: '30px' }}>Settings</h1>
      <div className='settings-scroll-container'>
        <div className='settings-section'>
          <h2>AI Model Selection</h2>
          <Select
            value={selectedLLM}
            onChange={handleLLMChange}
            style={{ width: '100%' }}
          >
            <Select.Option value='openai'>OpenAI</Select.Option>
            <Select.Option value='claude'>Claude</Select.Option>
            <Select.Option value='gemini'>Gemini</Select.Option>
            <Select.Option value='mixtral'>Mixtral</Select.Option>
            <Select.Option value='ollama'>Ollama (Open Source)</Select.Option>
          </Select>
        </div>

        <div className='settings-section'>
          <h2>Default Model</h2>
          <Select
            value={defaultModel}
            onChange={handleModelChange}
            style={{ width: '100%' }}
          >
            {getModelOptions().map(option => (
              <Select.Option key={option.value} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div className='settings-section'>
          <h2 style={{ fontSize: '26px' }}>API Keys</h2>
          {Object.keys(apiKeys).map(llm => (
            <div key={llm} className='api-key-input'>
              <label htmlFor={`${llm}-api-key`}>
                {llm.charAt(0).toUpperCase() + llm.slice(1)} API Key:
              </label>
              <Input.Password
                id={`${llm}-api-key`}
                value={apiKeys[llm]}
                onChange={e => handleApiKeyChange(llm, e.target.value)}
                placeholder={`Enter ${llm} API key`}
              />
            </div>
          ))}
        </div>

        <div className='settings-section'>
          <h3>ElevenLabs Settings</h3>
          <Input.Password
            placeholder='Enter ElevenLabs API Key'
            value={elevenlabsApiKey}
            onChange={e => setElevenlabsApiKey(e.target.value)}
            style={{ marginBottom: '10px' }}
          />
          <Select
            style={{ width: '100%', marginBottom: '10px' }}
            placeholder='Select a voice'
            onChange={setSelectedVoice}
            value={selectedVoice}
          >
            <Select.Option value='voice1'>Voice 1</Select.Option>
            <Select.Option value='voice2'>Voice 2</Select.Option>
            <Select.Option value='voice3'>Voice 3</Select.Option>
          </Select>
          <Switch
            // checkedChildren='Read Aloud On'
            // unCheckedChildren='Read Aloud Off'
            checked={readAloud}
            onChange={setReadAloud}
            style={{ marginBottom: '10px' }}
          />
          <Button
            style={{
              backgroundColor: 'var(--primary-color)',
              color: 'var(--text-color)',
              boxShadow: 'none',
              border: 'none',
              marginLeft: '10px',
              fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
            }}
            type='primary'
            onClick={handleSave}
          >
            Save Settings
          </Button>
        </div>

        <div className='settings-info'>
          <p>
            Note: API keys are stored locally in your browser. Please ensure
            you're using a secure connection.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Settings
