import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import './styles/AppContent.css';
import './styles/Sidebar.css';
import './styles/Dashboard.css';
import './styles/FileManagement.css';
import './styles/PlayerScreen.css';
import './styles/button.css';
import './styles/Layout.css';
import './styles/LoadingSpinner.css';
import './styles/LoginForm.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

console.log('REACT_APP_SUPABASE_URL:', process.env.REACT_APP_SUPABASE_URL);
console.log('REACT_APP_SUPABASE_ANON_KEY:', process.env.REACT_APP_SUPABASE_ANON_KEY);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
