import React from 'react'
import { Input, Button, Tooltip } from 'antd'
import { MessageOutlined, SendOutlined } from '@ant-design/icons'
import { AuthProvider, useAuth } from '../../AuthContext'
import './index.css'
import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  WarningOutlined
} from '@ant-design/icons' // Antd Icons
import AudioRecorder from '../AudioRecorder'
import PrimaryButton from '../ui/Buttons/primaryButton'
import DefaultButton from '../ui/Buttons/defaultButton'
import { LuFileAudio } from 'react-icons/lu'
import { FaArrowUp } from 'react-icons/fa'
import { FaMicrophone } from 'react-icons/fa'
// import { RiRobot2Line } from 'react-icons/ri'
import { RiRobot2Line } from 'react-icons/ri'
import {
  FiEdit2,
  FiRefreshCw,
  FiVolume2,
  FiThumbsUp,
  FiThumbsDown,
  FiShare2
} from 'react-icons/fi'
import PsychologyOutlinedIcon from '@mui/icons-material/PsychologyOutlined';
import logo from '../../assets/images/logo.png'

const MainChat = ({ messages, input, setInput, sendMessage, isLoading }) => {
  const { user, loading } = useAuth()
  console.log(messages)
  const examples = [
    'What can you do?',
    'Tell me a joke',
    'What’s the weather like?'
  ]

  const capabilities = [
    'Summarize articles',
    'Set reminders',
    'Perform calculations'
  ]
  
  const limitations = [
    'May provide outdated info',
    'Doesn’t have real-time updates',
    'Limited memory'
  ]

  const handleSendMessage = () => {
    sendMessage()
    setInput('') // Clear input
  }

  const handleInputKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <div className='chat-screen-container'>
      {/* If no messages, show the AI introduction and example prompts */}
      {messages.length === 0 ? (
        <div className='ai-intro-container'>
          <h2 className='ai-intro-title'>
            Welcome to the New Generation of AI
          </h2>
          <p className='ai-intro-description'>
            Use this AI to assist you with a variety of tasks like
            summarization, translation, reminders, and much more!
          </p>

          {/* 3-Column Grid */}
          <div className='ai-grid-container'>
            <div className='ai-column'>
              <div className='ai-column-header'>
                <InfoCircleOutlined className='ai-icon' />
                <h3>Examples</h3>
              </div>
              {examples.map((item, index) => (
                <div key={index} className='ai-grid-item'>
                  <div className='ai-inner-grid-item'>{item}</div>
                </div>
              ))}
            </div>

            <div className='ai-column'>
              <div className='ai-column-header'>
                <CheckCircleOutlined className='ai-icon' />
                <h3>Capabilities</h3>
              </div>
              {capabilities.map((item, index) => (
                <div key={index} className='ai-grid-item'>
                  <div className='ai-inner-grid-item'>{item}</div>
                </div>
              ))}
            </div>

            <div className='ai-column'>
              <div className='ai-column-header'>
                <WarningOutlined className='ai-icon' />
                <h3>Limitations</h3>
              </div>
              {limitations.map((item, index) => (
                <div key={index} className='ai-grid-item'>
                  <div className='ai-inner-grid-item'>{item}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className='chat-display'>
          {messages.map((message, index) => (
            <>
              {message.sender !== 'user' ? (
                // <div key={index} className={`message-bubble-ai`}>
                //   <div
                //     style={{
                //       padding: '10px',
                //       borderRadius: 999,
                //       backgroundColor: 'var(--primary-color)'
                //     }}
                //   >
                //     <RiRobot2Line color='var(--text-color)' />
                //   </div>
                //   <p className='chat-message-ai'>{message.text}</p>
                // </div>
                <div key={index} className='message-bubble-ai'>
                  {/* <div
                    style={{
                      padding: '10px',
                      borderRadius: '50%',
                      backgroundColor: 'var(--primary-color)'
                    }}
                  > */}
                    {/* <PsychologyOutlinedIcon color='var(--text-color)' /> */}
                    <img 
                      src={logo} 
                      alt="logo" 
                      style={{ 
                        width: '48px', 
                        height: '48px', 
                        minWidth: '35px', 
                        minHeight: '35px',
                        objectFit: 'cover',  // ensures the image covers the area without distortion
                        display: 'block'
                      }} 
                    />
                  {/* </div> */}

                  <div>
                    <p className='chat-message-ai'>{message.text}</p>

                    {/* Icon buttons under the message bubble */}
                    <div className='icon-buttons-container'>
                      <button className='icon-button'>
                        <Tooltip title='edit'>
                          <FiEdit2 style={{ color: "#FF1493" }}/>
                        </Tooltip>
                      </button>
                      <button className='icon-button'>
                        <Tooltip title='regenerate'>
                          <FiRefreshCw style={{ color: "#9400D3" }}/>
                        </Tooltip>
                      </button>
                      <button className='icon-button'>
                        <Tooltip title='read aloud'>
                          <FiVolume2 style={{ color: "#e53935" }}/>
                        </Tooltip>
                      </button>
                      <button className='icon-button'>
                        <Tooltip title='Like'>
                          <FiThumbsUp style={{ color: "#00CED1" }}/>
                        </Tooltip>
                      </button>
                      <button className='icon-button'>
                        <Tooltip title='dislike'>
                          <FiThumbsDown style={{ color: "#FF69B4" }}/>
                        </Tooltip>
                      </button>
                      <button className='icon-button'>
                        <Tooltip title='share'>
                          <FiShare2 style={{ color: "#ff5722" }}/>
                        </Tooltip>
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div key={index} className="message-bubble-user">
                  <p className="chat-message-user">{message.text}</p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '35px',
                      width: '35px',
                      minWidth: '35px',  // Ensures it doesn't shrink below this size
                      minHeight: '35px', // Ensures it doesn't shrink below this size
                      borderRadius: '50%',  // This ensures the circle shape
                      // backgroundColor: 'var(--primary-color)',
                      backgroundColor: '#e8056f',
                      fontSize: '18px',
                      // color: 'var(--text-color)',
                      color: 'white',
                      flexShrink: 0,  // Prevents the box from shrinking in flex layout
                    }}
                  >
                    {/* <RiRobot2Line color='var(--text-color)' /> */}
                    {user.email.charAt(0).toUpperCase()}
                  </div>
                </div>
              )}
            </>
          ))}
        </div>
      )}

      {/* Input Field */}
      <div className='main-chat-input-container'>
        <Input.TextArea
          placeholder='Type your message...'
          value={input}
          onChange={e => setInput(e.target.value)}
          className='chat-input'
          disabled={isLoading}
          autoSize={{ minRows: 1, maxRows: 6 }} // Expands vertically with input
          onPressEnter={handleInputKeyPress}
        />
        <div className='chat-input-buttons'>
          <DefaultButton shape='circle'>
            <Tooltip title={'Record audio'}>
              <FaMicrophone />
            </Tooltip>
          </DefaultButton>
          <AudioRecorder />
          <PrimaryButton
            shape='circle'
            icon={<FaArrowUp />}
            onClick={handleSendMessage}
            disabled={isLoading || input.trim() === ''}
          />
        </div>
      </div>
    </div>
  )
}

export default MainChat
