import React from 'react';
import { FiPause, FiStopCircle, FiPlay } from 'react-icons/fi';

const AudioControls = ({ isPaused, duration, onPause, onResume, onStop }) => {
  const formatDuration = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="audio-controls">
      <p className="text-lg font-semibold mb-2">
        Recording Audio: {formatDuration(duration)}
      </p>
      <div className="flex space-x-2">
        {isPaused ? (
          <button
            onClick={onResume}
            className="flex items-center px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            <FiPlay className="mr-2" /> Resume
          </button>
        ) : (
          <button
            onClick={onPause}
            className="flex items-center px-4 py-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
          >
            <FiPause className="mr-2" /> Pause
          </button>
        )}
        <button
          onClick={onStop}
          className="flex items-center px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          <FiStopCircle className="mr-2" /> Stop
        </button>
      </div>
    </div>
  );
};

export default AudioControls;
