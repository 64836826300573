// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal > .ant-modal-content {
  background-color: var(--bg-color) !important;
}`, "",{"version":3,"sources":["webpack://./src/components/AudioRecorder/index.css"],"names":[],"mappings":"AAAA;EACE,4CAA4C;AAC9C","sourcesContent":[".custom-modal > .ant-modal-content {\n  background-color: var(--bg-color) !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
