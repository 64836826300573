import { createClient } from "@supabase/supabase-js";
import { message } from "antd"; // Add this import
import { callAI } from "./AI"; // Add this import statement

// const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseUrl = "https://blfrvmapphmzvushkvea.supabase.co";
// const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;
const supabaseAnonKey =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImJsZnJ2bWFwcGhtenZ1c2hrdmVhIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjEzNDIyOTAsImV4cCI6MjAzNjkxODI5MH0.lS3S-z2pMEnRs7zIBRr1bxLM00dhlk2q3pGCetlPYAU";

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error("Supabase URL or Anon Key is missing. Check your .env file.");
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true,
  },
});

const generateFileName = (type) => {
  const now = new Date();
  const formattedDate = now
    .toLocaleString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    })
    .replace(/[/,:]/g, "_")
    .replace(/\s/g, "");

  if (type === "audio") {
    return `SR_audio_${formattedDate}.webm`;
  }
  return `${type}_${formattedDate}.webm`;
};

export const fetchRecordings = async (userId) => {
  try {
    const { data, error } = await supabase
      .from("recordings")
      .select("*")
      .eq("user_id", userId)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching recordings:", error);
    throw error;
  }
};

export const fetchFoldersWithRecordings = async (userId) => {
  try {
    const { data: folders, error: foldersError } = await supabase
      .from("folders")
      .select("*")
      .eq("user_id", userId);

    if (foldersError) throw foldersError;

    const { data: recordings, error: recordingsError } = await supabase
      .from("recordings")
      .select("*")
      .eq("user_id", userId)
      .order("created_at", { ascending: false });

    if (recordingsError) throw recordingsError;

    const validRecordings = recordings.filter(
      (recording) => recording !== null
    );

    const foldersWithRecordings = folders.map((folder) => ({
      ...folder,
      recordings: validRecordings.filter(
        (recording) => recording.folder_id === folder.id
      ),
    }));

    const rootRecordings = validRecordings.filter(
      (recording) => recording.folder_id === null
    );

    return { folders: foldersWithRecordings, rootRecordings };
  } catch (error) {
    console.error("Error fetching folders with recordings:", error);
    throw error;
  }
};

export const createFolder = async (
  userId,
  folderName,
  parentFolderId = null
) => {
  try {
    const { data, error } = await supabase
      .from("folders")
      .insert({
        user_id: userId,
        name: folderName,
        parent_folder_id: parentFolderId,
      })
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error creating folder:", error);
    throw error;
  }
};

export const deleteRecording = async (recordingId) => {
  try {
    console.log(`Attempting to delete recording with ID: ${recordingId}`);

    const { error: transcriptionError } = await supabase
      .from("transcriptions")
      .delete()
      .eq("recording_id", recordingId);

    if (transcriptionError) {
      console.error("Error deleting transcriptions:", transcriptionError);
      return {
        success: false,
        error: `Failed to delete transcriptions: ${transcriptionError.message}`,
      };
    }

    const { data: recording, error: fetchError } = await supabase
      .from("recordings")
      .select("*")
      .eq("id", recordingId)
      .single();

    if (fetchError) {
      console.error("Error fetching recording:", fetchError);
      return {
        success: false,
        error: `Failed to fetch recording: ${fetchError.message}`,
      };
    }

    const { error: storageError } = await supabase.storage
      .from("recordings")
      .remove([recording.url.split("/").pop()]);

    if (storageError) {
      console.error("Error deleting file from storage:", storageError);
      return {
        success: false,
        error: `Failed to delete file from storage: ${storageError.message}`,
      };
    }

    const { error: deleteError } = await supabase
      .from("recordings")
      .delete()
      .eq("id", recordingId);

    if (deleteError) {
      console.error("Error deleting recording:", deleteError);
      return {
        success: false,
        error: `Failed to delete recording: ${deleteError.message}`,
      };
    }

    console.log(`Successfully deleted recording with ID: ${recordingId}`);
    return { success: true };
  } catch (error) {
    console.error("Unexpected error deleting recording:", error);
    return {
      success: false,
      error: `Unexpected error occurred: ${error.message}`,
    };
  }
};

export const renameItem = async (itemId, newName, isFolder) => {
  try {
    const table = isFolder ? "folders" : "recordings";
    const { data, error } = await supabase
      .from(table)
      .update({ name: newName })
      .eq("id", itemId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error(
      `Error renaming ${isFolder ? "folder" : "recording"}:`,
      error
    );
    throw error;
  }
};

export const uploadRecording = async (userId, file, type, duration) => {
  try {
    console.log(
      `Uploading recording for user ${userId}, type: ${type}, duration: ${duration}`
    );
    const fileName = generateFileName(type);
    const filePath = `${userId}/${fileName}`;
    const { data, error } = await supabase.storage
      .from("recordings")
      .upload(filePath, file);

    if (error) {
      console.error("Error uploading file to storage:", error);
      throw error;
    }

    const { data: urlData } = supabase.storage
      .from("recordings")
      .getPublicUrl(filePath);

    const roundedDuration = Math.max(Math.round(duration), 1); // Ensure minimum duration of 1 second

    const { data: recordingData, error: recordingError } = await supabase
      .from("recordings")
      .insert({
        user_id: userId,
        name: fileName,
        url: urlData.publicUrl,
        type: type,
        duration: roundedDuration,
        metadata: {},
        folder_id: null,
      })
      .select()
      .single();

    if (recordingError) {
      console.error("Error inserting recording data:", recordingError);
      throw recordingError;
    }

    console.log("Recording data inserted successfully:", recordingData);
    return recordingData;
  } catch (error) {
    console.error("Error uploading recording:", error);
    throw error;
  }
};

export const uploadAudioRecording = async (userId, blob, duration) => {
  console.log(
    `Uploading audio recording for user ${userId}, duration: ${duration}`
  );
  return uploadRecording(userId, blob, "audio", duration);
};

export const uploadScreenRecording = async (userId, blob, duration) => {
  return uploadRecording(userId, blob, "screen", duration);
};

export const updateRecordingFolder = async (recordingId, folderId) => {
  try {
    const { data, error } = await supabase
      .from("recordings")
      .update({ folder_id: folderId })
      .eq("id", recordingId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating recording folder:", error);
    throw error;
  }
};

export const fetchResourceById = async (resourceId) => {
  try {
    const { data, error } = await supabase
      .from("recordings")
      .select("*")
      .eq("id", resourceId)
      .single();

    if (error) {
      if (error.code === "PGRST116") {
        console.log("DatabaseService: Resource not found");
        return null;
      }
      throw error;
    }
    return data;
  } catch (error) {
    console.error("Error fetching resource:", error);
    throw error;
  }
};

export const uploadTranscription = async (
  userId,
  transcriptionData,
  recordingId
) => {
  try {
    if (!userId || !transcriptionData || !recordingId) {
      throw new Error("Missing required data for transcription upload");
    }

    let parsedTranscription =
      typeof transcriptionData === "string"
        ? JSON.parse(transcriptionData)
        : transcriptionData;

    const transcriptionText = parsedTranscription.text || "";

    // Generate title
    const title = await generateTitleForTranscription(transcriptionText);

    // Insert transcription without title
    const { data, error } = await supabase
      .from("transcriptions")
      .insert({
        user_id: userId,
        recording_id: recordingId,
        content: parsedTranscription,
        text: transcriptionText,
      })
      .select()
      .single();

    if (error) {
      console.error("Error inserting transcription:", error);
      return { success: false, error: error.message };
    }

    if (!data) {
      return { success: false, error: "No data returned from Supabase" };
    }

    // Update recording name with the generated title
    await updateRecordingName(recordingId, title).catch((error) => {
      console.error("Error updating recording name:", error);
      // Continue execution even if updating the name fails
    });

    // Automatically generate summary after transcription upload
    await generateSummary(transcriptionText, recordingId).catch((error) => {
      console.error("Error generating summary:", error);
      // Continue execution even if summary generation fails
    });

    return { success: true, data: { ...data, title } };
  } catch (error) {
    console.error("Error uploading transcription:", error);
    return { success: false, error: error.message };
  }
};

const generateTitleForTranscription = async (transcriptionText) => {
  try {
    const selectedLLM = localStorage.getItem("selectedLLM") || "openai";
    const defaultModel =
      localStorage.getItem("defaultModel") || "gpt-3.5-turbo";
    const apiKeys = JSON.parse(localStorage.getItem("apiKeys")) || {};
    const apiKey = apiKeys[selectedLLM];

    if (!apiKey) {
      throw new Error(
        `No API key found for ${selectedLLM}. Please add it in the settings.`
      );
    }

    const systemPrompt = `You are a professional title generator. Create a short, catchy, and relevant title for the given transcript. The title should be no longer than 6 words.`;
    const userPrompt = `Please generate a title for the following transcript:\n\n${transcriptionText}`;

    const response = await callAI(
      `${systemPrompt}\n\n${userPrompt}`,
      selectedLLM,
      defaultModel,
      apiKey
    );

    return response.trim();
  } catch (error) {
    console.error("Error generating title:", error);
    return "Untitled Recording";
  }
};

export const fetchAllRecordings = async (userId) => {
  try {
    const { data, error } = await supabase
      .from("recordings")
      .select("*")
      .eq("user_id", userId)
      .order("created_at", { ascending: false });

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error fetching recordings:", error);
    throw error;
  }
};

export const saveChatSession = async (userId, sessionId, title, messages) => {
  try {
  console.log(userId,": User",sessionId,": sessionId")
    const { data, error } = await supabase
      .from("chat_sessions")
      .upsert(
        { user_id: userId, session_id: sessionId, title, messages },
        { onConflict: "session_id", returning: "minimal" }
      );

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error saving chat session:", error);
    throw error;
  }
};

export const loadChatSessions = async (userId) => {
  const { data, error } = await supabase
    .from("chat_sessions")
    .select("*")
    .eq("user_id", userId)
    .order("created_at", { ascending: false });

  if (error) throw error;
  return data;
};

export const deleteChatSession = async (sessionId) => {
  const { error } = await supabase
    .from("chat_sessions")
    .delete()
    .eq("session_id", sessionId);

  if (error) throw error;
};

export const fetchTranscription = async (recordingId) => {
  try {
    const { data, error } = await supabase
      .from("transcriptions")
      .select("*")
      .eq("recording_id", recordingId)
      .single();

    if (error) {
      if (error.code === "PGRST116") {
        console.log("No transcription found for this recording");
        return null;
      }
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching transcription:", error);
    return null;
  }
};

export const fetchSummary = async (recordingId) => {
  try {
    const { data, error } = await supabase
      .from("summaries")
      .select("*")
      .eq("recording_id", recordingId)
      .single();

    if (error) {
      if (error.code === "PGRST116") {
        // This error code means no rows were returned, which is expected when no summary exists
        return null;
      }
      // For other types of errors, we'll still throw
      throw error;
    }
    return data;
  } catch (error) {
    // Log the error but don't throw it
    console.error("Error fetching summary:", error);
    return null;
  }
};

export const updateRecordingName = async (recordingId, newName) => {
  try {
    const { data: currentRecording, error: fetchError } = await supabase
      .from("recordings")
      .select("*")
      .eq("id", recordingId)
      .single();

    if (fetchError) throw fetchError;

    const sanitizedNewName = sanitizeFileName(newName);
    const oldPath = currentRecording.url.split("/").slice(-2).join("/");
    const newPath = `${currentRecording.user_id}/${sanitizedNewName}`;

    // Check if the old file exists
    const { data: fileExists, error: checkError } = await supabase.storage
      .from("recordings")
      .list(currentRecording.user_id, {
        limit: 1,
        offset: 0,
        sortBy: { column: "name", order: "asc" },
        search: oldPath.split("/").pop(),
      });

    if (checkError || !fileExists || fileExists.length === 0) {
      console.error(`File not found: ${oldPath}`);
      // Update database record even if file is not found
      const { data, error } = await supabase
        .from("recordings")
        .update({ name: sanitizedNewName })
        .eq("id", recordingId)
        .select()
        .single();

      if (error) throw error;
      return data;
    }

    // Rename the file in storage
    const { error: moveError } = await supabase.storage
      .from("recordings")
      .move(oldPath, newPath);

    if (moveError) {
      console.error("Error moving file:", moveError);
      throw moveError;
    }

    // Update the URL in the database
    const { data: urlData } = supabase.storage
      .from("recordings")
      .getPublicUrl(newPath);

    const { data, error } = await supabase
      .from("recordings")
      .update({ name: sanitizedNewName, url: urlData.publicUrl })
      .eq("id", recordingId)
      .select()
      .single();

    if (error) throw error;
    return data;
  } catch (error) {
    console.error("Error updating recording name:", error);
    throw error;
  }
};

// New function to handle summary generation
const generateSummary = async (transcriptionText, recordingId) => {
  try {
    const selectedLLM = localStorage.getItem("selectedLLM") || "openai";
    const defaultModel =
      localStorage.getItem("defaultModel") || "gpt-3.5-turbo";
    const apiKeys = JSON.parse(localStorage.getItem("apiKeys")) || {};
    const apiKey = apiKeys[selectedLLM];

    if (!apiKey) {
      throw new Error(
        `No API key found for ${selectedLLM}. Please add it in the settings.`
      );
    }

    const systemPrompt = `As a professional summarizer, create a concise and comprehensive summary of the provided text.`;
    const userPrompt = `Please summarize the following transcript:\n\n${transcriptionText}`;

    const response = await callAI(
      `${systemPrompt}\n\n${userPrompt}`,
      selectedLLM,
      defaultModel,
      apiKey
    );

    // Save summary to Supabase
    const { error: saveError } = await supabase
      .from("summaries")
      .upsert(
        { recording_id: recordingId, content: response },
        { onConflict: "recording_id" }
      );

    if (saveError) throw saveError;

    message.success("Summary generated successfully.");
  } catch (error) {
    console.error("Error generating summary:", error);
    message.error("Failed to generate summary. Please try again.");
  }
};

const sanitizeFileName = (fileName) => {
  return fileName.replace(/['"]/g, "").replace(/[^a-zA-Z0-9._-]/g, "_"); // Replace invalid characters
};

export const getCurrentSubscription = async (userId) => {
  try {
    // Fetch the current active subscription for the given user_id
    const { data, error } = await supabase
      .from("subscriptions")
      .select("*")
      .eq("user_id", userId)
      .eq("is_active", true)
      .single(); // We expect only one active subscription

    // Error handling
    if (error) {
      console.error("Error fetching current subscription:", error.message);
      return null;
    }

    // Return the subscription data
    return data;
  } catch (err) {
    console.error("Unexpected error fetching subscription:", err.message);
    return null;
  }
};
