import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { FiPlay, FiPause, FiSquare } from 'react-icons/fi';
import { supabase, uploadAudioRecording, uploadTranscription } from '../DatabaseService';
import AndroidAudioRecording from './AndroidAudioRecording';

const AudioRecording = ({ onClose }) => {
  const [uploadStatus, setUploadStatus] = useState('');
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const finalDurationRef = useRef(0);

  const isAndroid = useMemo(() => /Android/i.test(navigator.userAgent), []);

  const startTimer = useCallback(() => {
    if (timerRef.current === null) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => {
          const newTime = prevTime + 1;
          console.log('Recording time updated:', newTime);
          return newTime;
        });
      }, 1000);
    }
  }, []);

  const stopTimer = useCallback(() => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const saveToSupabase = useCallback(async (audioBlob) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('User not authenticated');
        setUploadStatus('User not authenticated');
        return;
      }

      setUploadStatus('Uploading audio...');
      console.log('Recording time before upload:', finalDurationRef.current);

      const recordingData = await uploadAudioRecording(user.id, audioBlob, finalDurationRef.current);

      console.log('Audio recording uploaded successfully:', recordingData);
      setUploadStatus('Audio uploaded. Transcribing...');

      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.webm');

      const response = await fetch('http://localhost:5000/api/transcribe', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Transcription failed: ${response.statusText}`);
      }

      const transcriptionData = await response.json();

      console.log('Transcription data received:', transcriptionData);
      
      const transcriptionResult = await uploadTranscription(user.id, JSON.stringify(transcriptionData), recordingData.id);

      if (transcriptionResult.success) {
        console.log('Transcription uploaded successfully:', transcriptionResult.data);
        setUploadStatus('Upload and transcription complete');
      } else {
        console.error('Error uploading transcription:', transcriptionResult.error);
        setUploadStatus(`Error uploading transcription: ${transcriptionResult.error}`);
      }

      // Verify the uploaded transcription
      const { data: verificationData, error: verificationError } = await supabase
        .from('transcriptions')
        .select('content')
        .eq('recording_id', recordingData.id)
        .single();

      if (verificationError) {
        console.error('Error verifying transcription:', verificationError);
      } else {
        console.log('Verified uploaded transcription:', verificationData);
      }
    } catch (error) {
      console.error('Error in saveToSupabase:', error);
      setUploadStatus(`Error: ${error.message}`);
    }
  }, []);

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        console.log('Audio recording finished. Duration:', finalDurationRef.current);
        
        try {
          await saveToSupabase(audioBlob);
        } catch (error) {
          console.error('Error saving to Supabase:', error);
          setUploadStatus(`Failed to upload: ${error.message}`);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
      setIsPaused(false);
      setRecordingTime(0);
      finalDurationRef.current = 0;
      startTimer();
    } catch (error) {
      console.error('Error starting audio recording:', error);
      setUploadStatus(`Failed to start recording: ${error.message}`);
    }
  }, [saveToSupabase, startTimer]);

  const pauseRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      stopTimer();
      console.log('Recording paused. Current duration:', recordingTime);
    }
  }, [isRecording, stopTimer, recordingTime]);

  const resumeRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer();
      console.log('Recording resumed. Current duration:', recordingTime);
    }
  }, [isRecording, isPaused, startTimer, recordingTime]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      stopTimer();
      setIsRecording(false);
      setIsPaused(false);
      finalDurationRef.current = recordingTime;
      console.log('Recording stopped. Final duration:', finalDurationRef.current);
    }
  }, [isRecording, stopTimer, recordingTime]);

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  if (isAndroid) {
    return <AndroidAudioRecording onClose={onClose} />;
  }

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  return (
    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-4 text-gray-800 dark:text-white">Audio Recording</h2>
      <div className="text-4xl font-mono mb-4 text-gray-700 dark:text-gray-300">
        {formatTime(recordingTime)}
      </div>
      {uploadStatus && (
        <div className="mb-4 text-sm font-semibold text-blue-600 dark:text-blue-400">
          {uploadStatus}
        </div>
      )}
      <div className="space-x-4 overflow-auto max-h-96">
        {!isRecording ? (
          <button
            onClick={startRecording}
            className="px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          >
            <FiPlay className="inline-block mr-2" /> Start Recording
          </button>
        ) : (
          <>
            {isPaused ? (
              <button
                onClick={resumeRecording}
                className="px-6 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
              >
                <FiPlay className="inline-block mr-2" /> Resume
              </button>
            ) : (
              <button
                onClick={pauseRecording}
                className="px-6 py-2 bg-yellow-500 text-white rounded-md hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-opacity-50"
              >
                <FiPause className="inline-block mr-2" /> Pause
              </button>
            )}
            <button
              onClick={stopRecording}
              className="px-6 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
            >
              <FiSquare className="inline-block mr-2" /> Stop
            </button>
          </>
        )}
        <button
          onClick={onClose}
          className="px-6 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-400 focus:ring-opacity-50"
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default AudioRecording;
