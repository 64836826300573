import React, { useState, useEffect, useRef, useCallback } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  ClockCircleOutlined,
  PlayCircleOutlined,
  PauseCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  SendOutlined,
  FileTextOutlined
} from '@ant-design/icons'
import { Button, Spin, message, Modal, Slider, Typography, Layout } from 'antd'
import {
  fetchResourceById,
  fetchTranscription,
  fetchSummary,
  supabase,
  updateRecordingName
} from '../DatabaseService'
import { callAI } from '../AI'
import ReactPlayer from 'react-player'
import '../styles/PlayerScreen.css'
import axios from 'axios'
import { useDarkMode } from '../App'
import PrimaryButton from './ui/Buttons/primaryButton'

const { confirm } = Modal
const { Title, Paragraph } = Typography
const { Content, Sider } = Layout

const PlayerScreen = ({ user, onClose }) => {
  const { id } = useParams()
  const navigate = useNavigate()
  const containerRef = useRef(null)
  const playerRef = useRef(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [currentTime, setCurrentTime] = useState(0)
  const [duration, setDuration] = useState(0)
  const [error, setError] = useState(null)
  const [transcript, setTranscript] = useState([])
  const [videoUrl, setVideoUrl] = useState(null)
  const [isPortrait, setIsPortrait] = useState(false)
  const { isDarkMode } = useDarkMode()
  const [summary, setSummary] = useState(null)
  const [isSummarizing, setIsSummarizing] = useState(false)
  const [recordingName, setRecordingName] = useState('')

  const handleError = useCallback(message => {
    console.error(message)
    setError(message)
    message.error(message)
    setIsLoading(false)
  }, [])

  const loadResource = useCallback(async () => {
    setIsLoading(true)
    try {
      const data = await fetchResourceById(id)
      // console.log("data",data)
      if (data?.name && data?.url) {
        setRecordingName(data.name)
        await loadVideoFromStorage(data.name, data.url)
      } else {
        setError('No valid data received for this resource')
        return
      }

      const transcriptionData = await fetchTranscription(id)

      if (transcriptionData && transcriptionData.content) {
        let segments = []
        if (typeof transcriptionData.content === 'string') {
          try {
            const parsedContent = JSON.parse(transcriptionData.content)
            segments = parsedContent.transcriptionData?.segments || []
          } catch (e) {
            console.error('Error parsing transcription content:', e)
          }
        } else if (transcriptionData.content.transcriptionData?.segments) {
          segments = transcriptionData.content.transcriptionData.segments
        }

        // Log the segments to check their structure

        // Ensure segments have text before setting
        const validSegments = segments.filter(
          segment => segment.text && segment.text.trim() !== ''
        )
        setTranscript(validSegments)
      } else {
        setTranscript([])
        console.log('No valid transcription data found')
      }

      const summaryData = await fetchSummary(id)
      if (summaryData?.content) {
        setSummary(summaryData.content)
      } else {
        setSummary(null)
      }

      setIsLoading(false)
    } catch (error) {
      handleError(`Failed to load the resource: ${error.message}`)
    }
  }, [id, handleError])

  useEffect(() => {
    loadResource()
  }, [loadResource])

  const loadVideoFromStorage = async (fileName, fileUrl) => {
    try {
      // Extract the path from the full URL
      const urlParts = fileUrl.split('/')
      const filePath = urlParts
        .slice(urlParts.indexOf('recordings') + 1)
        .join('/')

      const { data, error } = await supabase.storage
        .from('recordings')
        .createSignedUrl(filePath, 3600)

      if (error) throw error
      if (!data?.signedUrl)
        throw new Error('No signed URL received from storage')

      setVideoUrl(data.signedUrl)
    } catch (error) {
      console.error('Error loading video from storage:', error)
      setError(`Failed to load video: ${error.message}`)
      message.error(`Failed to load video: ${error.message}`)
    }
  }

  const handlePlayPause = () => {
    console.log('Toggling play/pause. Current state:', isPlaying)
    setIsPlaying(!isPlaying)
  }

  const handleProgress = state => {
    console.log('Current playback state:', state)
    setCurrentTime(state.playedSeconds)
  }

  const handleDuration = duration => {
    console.log('Video duration:', duration)
    setDuration(duration)
  }

  const formatTime = time => {
    const minutes = Math.floor(time / 60)
    const seconds = Math.floor(time % 60)
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`
  }

  const handleVideoReady = player => {
    console.log('Video is ready to play.')
    const videoElement = player.getInternalPlayer()
    if (videoElement) {
      const { videoWidth, videoHeight } = videoElement
      setIsPortrait(videoHeight > videoWidth)
      console.log('Video dimensions:', { videoWidth, videoHeight })
    }
  }

  const handleSeek = value => {
    playerRef.current.seekTo(value, 'seconds')
  }

  const sendToNotion = async (content, type) => {
    if (!content) {
      message.warning(`No ${type} available to send.`)
      return
    }

    try {
      // const response = await axios.post('http://localhost:5000/notion', {
      const response = await axios.post('https://soloknows.com/notion', {
        transcriptionText: type === 'Transcript' ? content : content.trim(),
        title: recordingName // Corrected from recodingName to recordingName
      })

      if (response.status === 200) {
        message.success(`${type} sent to Notion successfully.`)
      } else {
        throw new Error(`Failed to send ${type} to Notion`)
      }
    } catch (error) {
      console.error(`Error sending ${type} to Notion:`, error)
      message.error(`Error sending ${type} to Notion: ${error.message}`)
    }
  }

  const handleGenerateSummary = useCallback(async () => {
    if (transcript.length === 0) {
      message.warning('No transcript available to summarize.')
      return
    }

    setIsSummarizing(true)
    try {
      const selectedLLM = localStorage.getItem('selectedLLM') || 'openai'
      const defaultModel =
        localStorage.getItem('defaultModel') || 'gpt-3.5-turbo'
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {}
      const apiKey = apiKeys[selectedLLM]

      if (!apiKey) {
        throw new Error(
          `No API key found for ${selectedLLM}. Please add it in the settings.`
        )
      }

      const systemPrompt = `As a professional summarizer, create a concise and comprehensive summary of the provided text, be it an article, post, conversation, or passage, while adhering to these guidelines:

Craft a summary that is detailed, thorough, in-depth, and complex, while maintaining clarity and conciseness.

Incorporate main ideas and essential information, eliminating extraneous language and focusing on critical aspects.

Rely strictly on the provided text, without including external information.

Format the summary in paragraph form for easy understanding.

Conclude your notes with [End of Notes, Message #1] to indicate completion.

By following this optimized prompt, you will generate an effective summary that encapsulates the essence of the given text in a clear, concise, and reader-friendly manner.`

      // Check and log the transcription segments

      const userPrompt = `Please summarize the following transcript:\n\n${transcript
        .map(segment => segment.text)
        .join(' ')}`

      // Log the user prompt being sent to the AI

      const response = await callAI(
        `${systemPrompt}\n\n${userPrompt}`,
        selectedLLM,
        defaultModel,
        apiKey
      )

      setSummary(response)

      // Save summary to Supabase
      const { error: saveError } = await supabase
        .from('summaries')
        .upsert(
          { recording_id: id, content: response },
          { onConflict: 'recording_id' }
        )

      if (saveError) throw saveError

      message.success('Summary generated successfully.')
    } catch (error) {
      console.error('Error generating summary:', error)
      message.error('Failed to generate summary. Please try again.')
    } finally {
      setIsSummarizing(false)
    }
  }, [id, transcript])

  const confirmClose = () => {
    confirm({
      title: 'Are you sure you want to close the player?',
      onOk () {
        onClose()
      },
      onCancel () {}
    })
  }

  if (isLoading) {
    return (
      <div className='loading-message'>
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
      </div>
    )
  }

  if (error) {
    return (
      <div className='error-message'>
        <Title level={2}>Error</Title>
        <Paragraph>{error}</Paragraph>
        <Button type='primary' onClick={onClose}>
          Close
        </Button>
      </div>
    )
  }

  return (
    <div className={`player-screen-container ${isDarkMode ? 'dark' : ''}`}>
      <Content className='player-content'>
        <Button
          style={{ margin: '0 0 0 -10px', width: '80px', color: isDarkMode ? 'white' : 'black' }}
          type='link'
          icon={<LeftOutlined />}
          onClick={confirmClose}
          // className='close-button'
        >
          Close
        </Button>
        <div className='video-section'>
          <Title level={3} style={{ color: 'var(--text-color)' }}>
            {recordingName}
          </Title>
          {videoUrl ? (
            <div
              className={`video-player-wrapper ${
                isPortrait ? 'portrait' : 'landscape'
              }`}
            >
              <ReactPlayer
                ref={playerRef}
                url={videoUrl}
                playing={isPlaying}
                width='100%'
                height='100%'
                onProgress={handleProgress}
                onDuration={handleDuration}
                onReady={handleVideoReady}
                className='react-player'
              />
              <div
                className='custom-controls'
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  borderRadius: '8px'
                }}
              >
                <PrimaryButton
                  // type='primary'
                  shape='circle'
                  icon={
                    isPlaying ? <PauseCircleOutlined /> : <PlayCircleOutlined />
                  }
                  onClick={handlePlayPause}
                />
                <Slider
                  min={0}
                  max={duration}
                  value={currentTime}
                  onChange={handleSeek}
                  tooltip={{ open: false }}
                  className='seek-bar'
                />
                <span className='time-display'>
                  {formatTime(currentTime)} / {formatTime(duration)}
                </span>
              </div>
            </div>
          ) : (
            <div className='no-video'>No video available</div>
          )}
        </div>
        {summary && (
          <div className='summary-section'>
            <Title level={4} style={{ color: 'var(--text-color)' }}>
              AI Summary
            </Title>
            <div className='summary-content custom-scrollbar'>
              <Paragraph style={{ color: 'var(--sec-text-color)' }}>
                {summary}
              </Paragraph>
            </div>
            <Button
              style={{
                backgroundColor: 'var(--primary-color)',
                color: 'var(--text-color)',
                border: 'none',
                boxShadow: 'none'
              }}
              type='primary'
              icon={<SendOutlined />}
              onClick={() => sendToNotion(summary, 'Summary')}
              disabled={isLoading || !summary}
            >
              Send Summary to Notion
            </Button>
          </div>
        )}
      </Content>
      <div className='transcript-sider'>
        <div className='transcript-section'>
          <Title level={4} style={{ color: 'var(--text-color)' }}>
            Transcript
          </Title>
          <div className='transcript-actions'>
            <Button
              style={{
                backgroundColor: 'var(--primary-color)',
                color: 'var(--text-color)',
                border: 'none',
                boxShadow: 'none'
              }}
              type='primary'
              icon={<SendOutlined />}
              onClick={() =>
                sendToNotion(
                  transcript.map(segment => segment.text).join(' '),
                  'Transcript'
                )
              }
              disabled={isLoading || !transcript.length}
            >
              Send to Notion
            </Button>
          </div>
          <div className='transcript-content custom-scrollbar'>
            {transcript && transcript.length > 0 ? (
              transcript.map((segment, index) => (
                <div key={index} className='transcript-segment'>
                  <div className='transcript-time'>
                    <ClockCircleOutlined style={{ marginRight: 8 }} />
                    {`${formatTime(segment.start)} - ${formatTime(
                      segment.end
                    )}`}
                  </div>
                  <div>{segment.text}</div>
                </div>
              ))
            ) : (
              <div style={{ color: 'var(--sec-text-color)' }}>
                No transcript available
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PlayerScreen
