import React, { useState } from 'react'
import { Button, Drawer, Tooltip, Typography } from 'antd'
import { CloseOutlined, UnorderedListOutlined } from '@ant-design/icons'
import PrimaryButton from '../ui/Buttons/primaryButton'
import DefaultButton from '../ui/Buttons/defaultButton'
import { MdOpenInNew } from 'react-icons/md'

const AntDrawer = ({ children, onAddNew }) => {
  const [open, setOpen] = useState(false)

  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <DefaultButton
        onClick={showDrawer}
        icon={<UnorderedListOutlined />}
        shape='circle'
      />
      <Drawer
        onClose={onClose}
        open={open}
        width={300}
        title='Chat History'
        extra={
          <DefaultButton shape='circle' onClick={onAddNew}>
            <Tooltip title='new chat'>
              <MdOpenInNew />
            </Tooltip>
          </DefaultButton>
        }
        closeIcon={<CloseOutlined style={{ color: 'var(--text-color)' }} />}
        style={{
          backgroundColor: 'var(--bg-color)',
          color: 'var(--text-color)',
          fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
        }}
      >
        {children}
      </Drawer>
    </>
  )
}

export default AntDrawer
