import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAuth } from '../AuthContext';
import { callAI } from '../AI';
import { saveChatSession, loadChatSessions, deleteChatSession } from '../DatabaseService';
import ChatList from './ChatList';
import '../styles/AIChat.css';
import ElevenLabsSettings from './ElevenLabsSettings'; // Import the new component
import Settings from './Settings'; // Import the settings component

const AIChat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const [selectedLLM, setSelectedLLM] = useState('openai');
  const [defaultModel, setDefaultModel] = useState('gpt-3.5-turbo');
  const [sessions, setSessions] = useState([]);
  const [currentSessionId, setCurrentSessionId] = useState(null);
  const [elevenLabsSettings, setElevenLabsSettings] = useState({
    apiKey: '',
    selectedVoice: '',
    readAloud: false,
  });

  const handleSaveElevenLabsSettings = (settings) => {
    setElevenLabsSettings(settings);
    localStorage.setItem('elevenLabsSettings', JSON.stringify(settings)); // Save to local storage
  };

  useEffect(() => {
    console.log('AIChat component mounted');
    const savedLLM = localStorage.getItem('selectedLLM') || 'openai';
    const savedModel = localStorage.getItem('defaultModel') || 'gpt-3.5-turbo';
    setSelectedLLM(savedLLM);
    setDefaultModel(savedModel);
    loadChatHistory();
  }, [user]);

  useEffect(() => {
    const savedSettings = JSON.parse(localStorage.getItem('elevenLabsSettings'));
    if (savedSettings) {
      setElevenLabsSettings(savedSettings);
    }
  }, []);

  const loadChatHistory = async () => {
    try {
      const chatSessions = await loadChatSessions(user.id);
      setSessions(chatSessions);
      if (chatSessions.length > 0) {
        setCurrentSessionId(chatSessions[0].session_id);
        setMessages(chatSessions[0].messages);
      } else {
        createNewSession();
      }
    } catch (error) {
      console.error('Error loading chat history:', error);
      if (error.code === '42P01') {
        console.log('Chat sessions table does not exist. Creating a new session.');
        createNewSession();
      }
    }
  };

  const createNewSession = () => {
    const newSessionId = uuidv4();
    setCurrentSessionId(newSessionId);
    setMessages([]);
    setSessions([{ session_id: newSessionId, title: 'New Chat', messages: [] }, ...sessions]);
  };

  const selectSession = (sessionId) => {
    const session = sessions.find(s => s.session_id === sessionId);
    if (session) {
      setCurrentSessionId(sessionId);
      setMessages(session.messages);
    }
  };

  const deleteSession = async (sessionId) => {
    try {
      await deleteChatSession(sessionId);
      setSessions(sessions.filter(s => s.session_id !== sessionId));
      if (currentSessionId === sessionId) {
        if (sessions.length > 1) {
          const newCurrentSession = sessions.find(s => s.session_id !== sessionId);
          setCurrentSessionId(newCurrentSession.session_id);
          setMessages(newCurrentSession.messages);
        } else {
          createNewSession();
        }
      }
    } catch (error) {
      console.error('Error deleting chat session:', error);
    }
  };

  const generateTitle = async (messages) => {
    try {
      const prompt = `Generate a short, concise title (max 5 words) for this conversation:\n\n${messages.map(m => `${m.sender}: ${m.text}`).join('\n')}`;
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {};
      const apiKey = apiKeys[selectedLLM];
      const title = await callAI(prompt, selectedLLM, defaultModel, apiKey);
      return title.trim();
    } catch (error) {
      console.error('Error generating title:', error);
      return 'New Chat';
    }
  };

  const sendMessage = async () => {
    if (!input.trim()) return;

    setIsLoading(true);
    console.log('Sending message:', input);

    try {
      const apiKeys = JSON.parse(localStorage.getItem('apiKeys')) || {};
      const apiKey = apiKeys[selectedLLM];

      if (!apiKey) {
        throw new Error(`No API key found for ${selectedLLM}. Please add it in the settings.`);
      }

      const userMessage = { text: input, sender: 'user' };
      const updatedMessages = [...messages, userMessage];
      setMessages(updatedMessages);
      setInput('');

      const response = await callAI(input, selectedLLM, defaultModel, apiKey);
      const aiMessage = { text: response, sender: 'ai' };
      const finalMessages = [...updatedMessages, aiMessage];
      setMessages(finalMessages);

      let title = sessions.find(s => s.session_id === currentSessionId)?.title;
      if (!title || title === 'New Chat') {
        title = await generateTitle(finalMessages);
      }

      await saveChatSession(user.id, currentSessionId, title, finalMessages);
      setSessions(prevSessions => 
        prevSessions.map(s => 
          s.session_id === currentSessionId ? { ...s, title, messages: finalMessages } : s
        )
      );

      console.log('Response received:', response);
    } catch (error) {
      console.error('Error sending message:', error);
      setMessages([...messages, { text: input, sender: 'user' }, { text: `Error: ${error.message}`, sender: 'ai' }]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="ai-chat-container">
      <ChatList 
        sessions={sessions}
        onSelectSession={selectSession}
        onDeleteSession={deleteSession}
        currentSessionId={currentSessionId}
      />
      <div className="chat-main">
        <h2>{sessions.find(s => s.session_id === currentSessionId)?.title || 'AI Chat'}</h2>
        <div className="chat-messages">
          {messages.map((message, index) => (
            <div key={index} className={`message ${message.sender}`}>
              {message.text}
            </div>
          ))}
        </div>
        <div className="chat-input">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            disabled={isLoading}
          />
          <button onClick={sendMessage} disabled={isLoading}>
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default AIChat;