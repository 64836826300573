import { Button, Tooltip } from 'antd'
import React from 'react'
import { FiTrash2 } from 'react-icons/fi'
import { MdOpenInNew } from 'react-icons/md'
import DefaultButton from './ui/Buttons/defaultButton'

const ChatList = ({
  sessions,
  onSelectSession,
  onDeleteSession,
  currentSessionId,
  onNewSession
}) => {
  return (
    <div className='chat-list'>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "1rem",
        }}
      >
        <h3
          style={{
            color: "var(--text-color)",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          Chat History
        </h3>
        
        <DefaultButton
          shape="circle" 
          icon={<MdOpenInNew />}
          onClick={onNewSession}
        />
        
      </div> */}
      <ul>
        {sessions.map(session => (
          <li
            key={session.session_id}
            className={session.session_id === currentSessionId ? 'active' : ''}
            onClick={() => onSelectSession(session.session_id)}
          >
            {session.title}
            <button
              onClick={e => {
                e.stopPropagation()
                onDeleteSession(session.session_id)
              }}
            >
              <FiTrash2 />
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ChatList
