// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dashboard.css */

.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  background-color: var(--bg-color);
  color: var(--text-color);
}

.dashboard-content {
  flex: 1 1;
  overflow-y: auto;
  padding: 1rem;
  padding-bottom: 80px; /* Adjust based on your chat component height */
  display: flex;
  flex-direction: column;
  margin-left: 250px; /* Adjust based on your sidebar width */
}

.events-section {
  margin-bottom: 2rem;
}

.recordings-section {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px; /* Space for chat component */
}

/* Other styles remain the same */

@media (max-width: 768px) {
  .dashboard-content {
    margin-left: 0;
    padding-bottom: 100px; /* Increase padding for mobile devices */
  }

  .recordings-section {
    margin-bottom: 100px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/styles/Dashboard.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;EACb,gBAAgB;EAChB,iCAAiC;EACjC,wBAAwB;AAC1B;;AAEA;EACE,SAAO;EACP,gBAAgB;EAChB,aAAa;EACb,oBAAoB,EAAE,+CAA+C;EACrE,aAAa;EACb,sBAAsB;EACtB,kBAAkB,EAAE,uCAAuC;AAC7D;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,mBAAmB,EAAE,6BAA6B;AACpD;;AAEA,iCAAiC;;AAEjC;EACE;IACE,cAAc;IACd,qBAAqB,EAAE,wCAAwC;EACjE;;EAEA;IACE,oBAAoB;EACtB;AACF","sourcesContent":["/* Dashboard.css */\n\n.dashboard {\n  display: flex;\n  flex-direction: column;\n  height: 100vh;\n  overflow: hidden;\n  background-color: var(--bg-color);\n  color: var(--text-color);\n}\n\n.dashboard-content {\n  flex: 1;\n  overflow-y: auto;\n  padding: 1rem;\n  padding-bottom: 80px; /* Adjust based on your chat component height */\n  display: flex;\n  flex-direction: column;\n  margin-left: 250px; /* Adjust based on your sidebar width */\n}\n\n.events-section {\n  margin-bottom: 2rem;\n}\n\n.recordings-section {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 80px; /* Space for chat component */\n}\n\n/* Other styles remain the same */\n\n@media (max-width: 768px) {\n  .dashboard-content {\n    margin-left: 0;\n    padding-bottom: 100px; /* Increase padding for mobile devices */\n  }\n\n  .recordings-section {\n    margin-bottom: 100px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
