// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Layout.css */

.app-layout {
  display: flex;
  /* height: 100vh; */
  width: 100%;
  /* overflow: hidden; */
  background-color: var(--bg-color);
  color: var(--text-color);
  transition: background-color 0.3s ease, color 0.3s ease;
  /* margin-bottom: 4rem; */
}

.app-main {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  transition: margin-left 0.3s ease-in-out;
}

.sidebar-open .app-main {
  margin-left: 16rem; /* Width of the sidebar */
}

.app-layout.dark {
  background-color: var(--bg-color);
  color: var(--text-color);
}

@media (max-width: 768px) {
  .app-layout {
    flex-direction: column;
  }

  .app-main {
    margin-left: 0 !important;
  }

  .sidebar-open .app-main {
    margin-left: 0;
  }
}

/* Ensure content doesn't overlap with the sidebar toggle button on mobile */
@media (max-width: 768px) {
  .app-main {
    padding-bottom: 100px;
  }
}

/* Prevent horizontal scrolling */
html,
body {
  overflow-x: hidden;
}

/* Smooth scrolling for the entire page */
html {
  scroll-behavior: smooth;
}
`, "",{"version":3,"sources":["webpack://./src/styles/Layout.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,sBAAsB;EACtB,iCAAiC;EACjC,wBAAwB;EACxB,uDAAuD;EACvD,yBAAyB;AAC3B;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;EACtB,sBAAsB;EACtB,wCAAwC;AAC1C;;AAEA;EACE,kBAAkB,EAAE,yBAAyB;AAC/C;;AAEA;EACE,iCAAiC;EACjC,wBAAwB;AAC1B;;AAEA;EACE;IACE,sBAAsB;EACxB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,cAAc;EAChB;AACF;;AAEA,4EAA4E;AAC5E;EACE;IACE,qBAAqB;EACvB;AACF;;AAEA,iCAAiC;AACjC;;EAEE,kBAAkB;AACpB;;AAEA,yCAAyC;AACzC;EACE,uBAAuB;AACzB","sourcesContent":["/* Layout.css */\n\n.app-layout {\n  display: flex;\n  /* height: 100vh; */\n  width: 100%;\n  /* overflow: hidden; */\n  background-color: var(--bg-color);\n  color: var(--text-color);\n  transition: background-color 0.3s ease, color 0.3s ease;\n  /* margin-bottom: 4rem; */\n}\n\n.app-main {\n  flex: 1;\n  display: flex;\n  flex-direction: column;\n  /* overflow: hidden; */\n  transition: margin-left 0.3s ease-in-out;\n}\n\n.sidebar-open .app-main {\n  margin-left: 16rem; /* Width of the sidebar */\n}\n\n.app-layout.dark {\n  background-color: var(--bg-color);\n  color: var(--text-color);\n}\n\n@media (max-width: 768px) {\n  .app-layout {\n    flex-direction: column;\n  }\n\n  .app-main {\n    margin-left: 0 !important;\n  }\n\n  .sidebar-open .app-main {\n    margin-left: 0;\n  }\n}\n\n/* Ensure content doesn't overlap with the sidebar toggle button on mobile */\n@media (max-width: 768px) {\n  .app-main {\n    padding-bottom: 100px;\n  }\n}\n\n/* Prevent horizontal scrolling */\nhtml,\nbody {\n  overflow-x: hidden;\n}\n\n/* Smooth scrolling for the entire page */\nhtml {\n  scroll-behavior: smooth;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
