import React, { useEffect, useState, useCallback } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
  useParams,
} from "react-router-dom";
import { message } from "antd";
import { useAuth } from "./AuthContext";
import {
  supabase,
  uploadRecording,
  uploadTranscription,
} from "./DatabaseService";
import Dashboard from "./components/Dashboard";
import FileManagement from "./components/FileManagement";
import ResourceManager from "./components/ResourceManager";
import Sidebar from "./components/Sidebar";
import PlayerScreen from "./components/PlayerScreen";
import AudioRecording from "./components/AudioRecording";
import PrivateRoute from "./PrivateRoute";
import { formatDuration } from "./utils/formatDuration";
import { useRecordingLogic } from "./hooks/useRecordingLogic";
import RecordingOptions from "./components/RecordingOptions";
import FileUpload from "./components/FileUpload";
import { useDarkMode } from "./App";
import Settings from "./components/Settings";
import AIChat from "./components/AIChat";
import SubscriptionPlans from "./components/SubscriptionPage";

import "./styles/AppContent.css";

const AppContent = () => {
  const { user, signOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [recordings, setRecordings] = useState([]);
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showAudioRecording, setShowAudioRecording] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(window.innerWidth > 768);
  const { isDarkMode } = useDarkMode();

  const {
    showRecordingOptions,
    setShowRecordingOptions,
    startScreenSelection,
  } = useRecordingLogic(user, setRecordings, setError);

  useEffect(() => {
    if (location.pathname.startsWith("/player/")) {
      console.log(
        "AppContent: PlayerScreen should be shown, path:",
        location.pathname
      );
    }
  }, [location.pathname]);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [recordingsData, foldersData] = await Promise.all([
        supabase.from("recordings").select("*").eq("user_id", user.id),
        supabase.from("folders").select("*").eq("user_id", user.id),
      ]);

      if (recordingsData.error) throw recordingsData.error;
      if (foldersData.error) throw foldersData.error;

      setRecordings(
        recordingsData.data.map((recording) => ({
          ...recording,
          duration: formatDuration(recording.duration || 0),
          creator: user.email,
          dateCreated: new Date(recording.created_at).toLocaleString(),
        }))
      );
      setFolders(foldersData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Failed to fetch data. Please try again.");
    } finally {
      setIsLoading(false);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchData();
      const channel = supabase
        .channel("public:recordings")
        .on(
          "postgres_changes",
          { event: "*", schema: "public", table: "recordings" },
          fetchData
        )
        .subscribe();

      return () => {
        if (channel) {
          channel.unsubscribe();
        }
      };
    } else {
      setRecordings([]);
      setFolders([]);
      setIsLoading(false);
    }
  }, [user, fetchData]);

  const handleSignOut = useCallback(async () => {
    try {
      await signOut();
      navigate("/login");
    } catch (error) {
      console.error("Error signing out:", error);
      setError("Failed to sign out. Please try again.");
    }
  }, [signOut, navigate]);

  const handleRecordScreen = useCallback(() => {
    setShowAudioRecording(false);
    setShowFileUpload(false);
    setShowRecordingOptions(true);
  }, [setShowRecordingOptions]);

  const handleShowAudioRecording = useCallback(() => {
    setShowRecordingOptions(false);
    setShowFileUpload(false);
    setShowAudioRecording(true);
  }, [setShowRecordingOptions]);

  const handleCloseAudioRecording = useCallback(() => {
    setShowAudioRecording(false);
  }, []);

  const handleImportFiles = useCallback(() => {
    setShowRecordingOptions(false);
    setShowAudioRecording(false);
    setShowFileUpload(true);
  }, []);

  const handleFileUpload = async (file) => {
    try {
      setError(null);
      const fileType = file.type.startsWith("audio/") ? "audio" : "video";
      const duration = 0;

      const recordingData = await uploadRecording(
        user.id,
        file,
        fileType,
        duration
      );

      console.log("File uploaded successfully:", recordingData);
      message.success("File uploaded successfully. Transcribing...");

      const formData = new FormData();
      formData.append("audio", file);

      // const response = await fetch('http://localhost:5000/api/transcribe', {
      const response = await fetch("https://soloknows.com/api/transcribe", {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Transcription failed: ${response.statusText}`);
      }

      const transcriptionData = await response.json();

      console.log("Transcription data received:", transcriptionData);

      const transcriptionResult = await uploadTranscription(
        user.id,
        JSON.stringify(transcriptionData),
        recordingData.id
      );

      if (transcriptionResult.success) {
        console.log(
          "Transcription uploaded successfully:",
          transcriptionResult.data
        );
        message.success("Upload and transcription complete");
      } else {
        console.error(
          "Error uploading transcription:",
          transcriptionResult.error
        );
        message.error(
          `Error uploading transcription: ${transcriptionResult.error}`
        );
      }

      setShowFileUpload(false);
      fetchData();
    } catch (error) {
      console.error("Error uploading file:", error);
      message.error(`Failed to upload file: ${error.message}`);
    }
  };

  const handleTranscribeMeeting = useCallback(() => {
    message.info("Transcribe meeting functionality not implemented yet");
  }, []);

  const handleRecordVideo = useCallback(() => {
    message.info("Record video functionality not implemented yet");
  }, []);

  const handleClosePlayerScreen = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleSidebarToggle = useCallback((isOpen) => {
    setSidebarOpen(isOpen);
  }, []);

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  const PlayerScreenWrapper = () => {
    const { id } = useParams();

    if (!id || id === "undefined") {
      console.log("AppContent: Invalid ID, redirecting to dashboard");
      return <Navigate to="/dashboard" replace />;
    }
    return <PlayerScreen user={user} onClose={handleClosePlayerScreen} />;
  };

  const renderMainContent = () => {
    if (showAudioRecording) {
      return <AudioRecording onClose={handleCloseAudioRecording} />;
    }

    if (showRecordingOptions) {
      return (
        <RecordingOptions
          onStartRecording={startScreenSelection}
          onCancel={() => setShowRecordingOptions(false)}
        />
      );
    }

    if (showFileUpload) {
      return (
        <FileUpload
          onUpload={handleFileUpload}
          onClose={() => setShowFileUpload(false)}
        />
      );
    }

    return (
      <Routes>
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <Dashboard
                user={user}
                recordings={recordings}
                folders={folders}
                isLoading={isLoading}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/files"
          element={
            <PrivateRoute>
              <FileManagement
                user={user}
                recordings={recordings}
                setRecordings={setRecordings}
                folders={folders}
                setFolders={setFolders}
                isLoading={isLoading}
                navigate={navigate}
              />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route
          path="/subscription"
          element={
            <PrivateRoute>
              <SubscriptionPlans />
            </PrivateRoute>
          }
        />
        <Route
          path="/resource/:id"
          element={
            <PrivateRoute>
              <ResourceManager user={user} />
            </PrivateRoute>
          }
        />
        <Route
          path="/player/:id"
          element={
            <PrivateRoute>
              <PlayerScreenWrapper />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route
          path="/ai-chat"
          element={
            <PrivateRoute>
              <AIChat />
            </PrivateRoute>
          }
        />
      </Routes>
    );
  };

  return (
    <div
      className={`app-container ${
        sidebarOpen ? "sidebar-open" : "sidebar-closed"
      } ${isDarkMode ? "dark" : ""}`}
    >
      <Sidebar
        user={user}
        onSignOut={handleSignOut}
        onRecordScreen={handleRecordScreen}
        onShowAudioRecording={handleShowAudioRecording}
        onImportFiles={handleImportFiles}
        onTranscribeMeeting={handleTranscribeMeeting}
        onRecordVideo={handleRecordVideo}
        onSidebarToggle={handleSidebarToggle}
      />
      <main className="main-content">{renderMainContent()}</main>
      {error && (
        <div className="error-message" role="alert">
          {error}
        </div>
      )}
    </div>
  );
};

export default AppContent;
