import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  // CalendarOutlined,
  PlayCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  // SettingOutlined,
  // AudioOutlined,
  // FolderOutlined,
  // PauseCircleOutlined,
  // StopOutlined,
} from "@ant-design/icons";
import {
  Button,
  // Table,
  Typography,
  message,
  Popconfirm,
  Card,
  List,
  Space,
} from "antd";
import "../styles/Dashboard.css";
import FileUpload from "./FileUpload";
import { useDarkMode } from "../App";
import {
  // supabase,
  // uploadAudioRecording,
  // uploadTranscription,
  deleteRecording,
} from "../DatabaseService";
// import img1 from "../assets/icons/outlook.png";
// import img2 from "../assets/icons/calendar.png";
import DashboardRecents from "./DashboardRecents";
import ConnectApps from "./ConnectAppsText";
import DashboardChatComponent from "./DashboardChat";

const { Title, Paragraph, Text } = Typography;

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

const RecordingCard = ({ recording, onEdit, onDelete, onPlay, isDeleting }) => {
  return (
    <Card
      size="small"
      className="recording-card"
      actions={[
        <PlayCircleOutlined key="play" onClick={() => onPlay(recording.id)} />,
        <EditOutlined key="edit" onClick={() => onEdit(recording.id)} />,
        <Popconfirm
          title="Are you sure you want to delete this recording?"
          onConfirm={() => onDelete(recording.id)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ loading: isDeleting }}
        >
          <DeleteOutlined key="delete" />
        </Popconfirm>,
      ]}
    >
      <Card.Meta
        title={<Text ellipsis>{recording.name}</Text>}
        description={
          <Space direction="vertical" size={0}>
            <Text type="secondary">{recording.dateCreated}</Text>
            <Text type="secondary">{recording.duration}</Text>
          </Space>
        }
      />
    </Card>
  );
};

const Dashboard = ({
  user,
  recordings,
  folders,
  isLoading,
  setRecordings,
  setError,
}) => {
  const [showFileUpload, setShowFileUpload] = useState(false);
  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();
  const isMobile = useMediaQuery("(max-width: 768px)");







  const [deletingRecordingId, setDeletingRecordingId] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);




































































































































































  if (isLoading) {
    return <div className="dashboard-loading">Loading...</div>;
  }

  const handleFileUpload = (acceptedFiles) => {
    console.log("Uploaded files:", acceptedFiles);
    setShowFileUpload(false);
  };

  const handleEdit = (id) => {
    // Implement edit functionality
    message.info(`Editing recording ${id}`);
  };

  const handleDelete = async (id) => {
    try {
      setDeletingRecordingId(id);
      const recordingToDelete = recordings.find(
        (recording) => recording.id === id
      );
      const recordingName = recordingToDelete
        ? recordingToDelete.name
        : "Unknown";

      console.log(
        `Attempting to delete recording: ${recordingName} (ID: ${id})`
      );
      const result = await deleteRecording(id);

      if (result.success) {
        setRecordings(recordings.filter((recording) => recording.id !== id));
        message.success(`Recording "${recordingName}" deleted successfully`);
        console.log(
          `Successfully deleted recording: ${recordingName} (ID: ${id})`
        );
      } else {
        message.error(
          `Failed to delete recording "${recordingName}": ${result.error}`
        );
        console.error(
          `Failed to delete recording: ${recordingName} (ID: ${id})`,
          result.error
        );
      }
    } catch (error) {
      console.error(`Error deleting recording (ID: ${id}):`, error);
      message.error(`Error deleting recording: ${error.message}`);
    } finally {
      setDeletingRecordingId(null);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      message.info("No recordings selected for deletion");
      return;
    }

    setIsBulkDeleting(true);
    let successCount = 0;
    let failCount = 0;

    for (const id of selectedRowKeys) {
      if (id) {
        // Ensure the id is not undefined
        try {
          console.log(`Attempting to delete recording with ID: ${id}`);
          const result = await deleteRecording(id);
          if (result.success) {
            successCount++;
            console.log(`Successfully deleted recording with ID: ${id}`);
          } else {
            failCount++;
            console.error(
              `Failed to delete recording (ID: ${id}):`,
              result.error
            );
          }
        } catch (error) {
          failCount++;
          console.error(`Error deleting recording (ID: ${id}):`, error);
        }
      } else {
        console.error("Encountered undefined recording ID during bulk delete");
        failCount++;
      }
    }

    setRecordings((prevRecordings) =>
      prevRecordings.filter(
        (recording) => !selectedRowKeys.includes(recording.id)
      )
    );
    setSelectedRowKeys([]);
    setIsBulkDeleting(false);

    if (successCount > 0) {
      message.success(`Successfully deleted ${successCount} recording(s)`);
    }
    if (failCount > 0) {
      message.error(`Failed to delete ${failCount} recording(s)`);
    }
  };


























































  const openPlayerScreen = (recordingId) => {
    console.log("Dashboard: Navigate to PlayerScreen with ID:", recordingId);
    navigate(`/player/${recordingId}`);
  };
















  // Sort recordings by date, newest first
  const sortedRecordings = [...recordings].sort(
    (a, b) => new Date(b.dateCreated) - new Date(a.dateCreated)
  );

  // Limit to 5 recordings for mobile view
  const mobileRecordings = sortedRecordings.slice(0, 5);









  const dummydata = [
    { name: "Audio 1", duration: "3 mins", time: "10:30 AM" },
    { name: "Audio 2", duration: "5 mins", time: "11:00 AM" },
  ];

  const dummydata2 = [
    { name: "How to cook a simple breakfast", time: "10:00 AM" },
    { name: "Help with email writing", time: "1:00 PM" },
  ];

  return (
    <div className={`dashboard ${isDarkMode ? "dark" : ""}`}>
      <div className="dashboard-content">
        <section className="events-section">

          <Paragraph style={{color:'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif'}}>
            Open your calendar (Google Calendar or Outlook) and create events.
          </Paragraph>
          <div className="events-container" style={{border:'none',padding:'0 !important'}}>
            <div className="no-events">


            </div>


            <ConnectApps />
          </div>
        </section>
        <section className="recordings-section">
          <div className="recordings-header">
            <Title level={4} style={{color:'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif'}}>Recents</Title>









          </div>
          {showFileUpload && (
            <div className="file-upload-container">
              <FileUpload onUpload={handleFileUpload} />
            </div>
          )}
          <div className="recordings-table-container">

















              <div className="recents-inner-container">
                <DashboardRecents
                  heading={"Recordings"}
                  data={dummydata}
                  name={true}
                  time={true}
                  duration={true}
                />
                <DashboardRecents
                  heading={"Chats"}
                  data={dummydata2}
                  name={true}
                  time={true}
                />
                <DashboardRecents
                  heading={"Recordings"}
                  data={dummydata}
                  name={true}
                  time={true}
                  duration={true}
                />
              </div>













          </div>

        </section>   




        <DashboardChatComponent />
      </div>




















































    </div>
  );
};

export default Dashboard;
