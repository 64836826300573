import React, { useState } from 'react';
import { Button } from "./ui/button";

const RecordingOptions = ({ onStartRecording, onCancel, setError }) => {
  const [videoOption, setVideoOption] = useState('screenOnly');
  const [audioOption, setAudioOption] = useState('systemAudio');

  const handleStartRecording = () => {
    onStartRecording({
      videoOption: videoOption,
      audioOption: audioOption
    });
  };


  const getVideoOptionLabel = (option) => {
    switch (option) {
      case 'screenOnly': return 'Screen Only';
      case 'screenAndCamera': return 'Screen + Camera';
      case 'cameraOnly': return 'Camera Only';
      default: return '';
    }
  };

  const getAudioOptionLabel = (option) => {
    switch (option) {
      case 'systemAudio': return 'System Audio';
      case 'micAudio': return 'Mic Audio';
      case 'both': return 'Both';
      default: return '';
    }
  };

  return (
    <div className="p-4 bg-white dark:bg-gray-800 rounded-lg shadow">
      <h2 className="text-xl font-semibold mb-4 text-gray-800 dark:text-gray-200">Recording Options</h2>
      
      <div className="mb-4">
        <h3 className="font-medium mb-2 text-gray-700 dark:text-gray-300">Video Options</h3>
        <div className="space-y-2">
          {['screenOnly', 'screenAndCamera', 'cameraOnly'].map((option) => (
            <label key={option} className="flex items-center text-gray-600 dark:text-gray-400">
              <input
                type="radio"
                value={option}
                checked={videoOption === option}
                onChange={(e) => setVideoOption(e.target.value)}
                className="mr-2"
              />
              {getVideoOptionLabel(option)}
            </label>
          ))}
        </div>
      </div>

      <div className="mb-4">
        <h3 className="font-medium mb-2 text-gray-700 dark:text-gray-300">Audio Options</h3>
        <div className="space-y-2">
          {['systemAudio', 'micAudio', 'both'].map((option) => (
            <label key={option} className="flex items-center text-gray-600 dark:text-gray-400">
              <input
                type="radio"
                value={option}
                checked={audioOption === option}
                onChange={(e) => setAudioOption(e.target.value)}
                className="mr-2"
              />
              {getAudioOptionLabel(option)}
            </label>
          ))}
        </div>
      </div>

      <div className="flex justify-between">
        <Button onClick={onCancel} variant="outline" className="dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-600">
          Cancel
        </Button>
        <Button onClick={handleStartRecording} className="dark:bg-blue-600 dark:hover:bg-blue-700">
          Start Recording
        </Button>
      </div>
    </div>
  );
};

export default RecordingOptions;
