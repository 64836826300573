import axios from 'axios';

// OpenAI API
const createOpenAIApi = (apiKey) => axios.create({
  baseURL: 'https://api.openai.com/v1',
  headers: {
    'Authorization': `Bearer ${apiKey}`,
    'Content-Type': 'application/json'
  }
});

// Anthropic (Claude) API
const createAnthropicApi = (apiKey) => axios.create({
  baseURL: 'https://api.anthropic.com/v1',
  headers: {
    'Authorization': `Bearer ${apiKey}`,
    'Content-Type': 'application/json'
  }
});

// Google (Gemini) API
const createGeminiApi = (apiKey) => axios.create({
  baseURL: 'https://generativelanguage.googleapis.com/v1beta',
  headers: {
    'Authorization': `Bearer ${apiKey}`,
    'Content-Type': 'application/json'
  }
});

// Mixtral API (assuming it has a REST API, adjust as needed)
const createMixtralApi = (apiKey) => axios.create({
  baseURL: 'https://api.mixtral.ai/v1', // Replace with actual Mixtral API URL
  headers: {
    'Authorization': `Bearer ${apiKey}`,
    'Content-Type': 'application/json'
  }
});

// Ollama API (for open-source models)
const createOllamaApi = () => axios.create({
  baseURL: 'http://localhost:11434', // Assuming Ollama is running locally
  headers: {
    'Content-Type': 'application/json'
  }
});

// Function to call OpenAI API
export const callOpenAI = async (prompt, model = 'gpt-3.5-turbo', apiKey) => {
  try {
    const openaiApi = createOpenAIApi(apiKey);
    const response = await openaiApi.post('/chat/completions', {
      model: model,
      messages: [{ role: 'user', content: prompt }]
    });
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('OpenAI API Error:', error);
    throw error;
  }
};

// Function to call Claude API
export const callClaude = async (prompt, model = 'claude-2', apiKey) => {
  try {
    const anthropicApi = createAnthropicApi(apiKey);
    const response = await anthropicApi.post('/complete', {
      model: model,
      prompt: prompt,
      max_tokens_to_sample: 300
    });
    return response.data.completion;
  } catch (error) {
    console.error('Claude API Error:', error);
    throw error;
  }
};

// Function to call Gemini API
export const callGemini = async (prompt, model = 'gemini-pro', apiKey) => {
  try {
    const geminiApi = createGeminiApi(apiKey);
    const response = await geminiApi.post(`/models/${model}:generateContent`, {
      contents: [{ parts: [{ text: prompt }] }]
    });
    return response.data.candidates[0].content.parts[0].text;
  } catch (error) {
    console.error('Gemini API Error:', error);
    throw error;
  }
};

// Function to call Mixtral API (adjust based on actual API structure)
export const callMixtral = async (prompt, model = 'mixtral-8x7b', apiKey) => {
  try {
    const mixtralApi = createMixtralApi(apiKey);
    const response = await mixtralApi.post('/generate', {
      model: model,
      prompt: prompt
    });
    return response.data.generated_text;
  } catch (error) {
    console.error('Mixtral API Error:', error);
    throw error;
  }
};

// Function to call Ollama API
export const callOllama = async (prompt, model = 'llama2') => {
  try {
    const ollamaApi = createOllamaApi();
    const response = await ollamaApi.post('/api/generate', {
      model: model,
      prompt: prompt
    });
    return response.data.response;
  } catch (error) {
    console.error('Ollama API Error:', error);
    throw error;
  }
};

// Generic function to call any AI model
export const callAI = async (prompt, service, model, apiKey) => {
  switch (service) {
    case 'openai':
      return await callOpenAI(prompt, model, apiKey);
    case 'claude':
      return await callClaude(prompt, model, apiKey);
    case 'gemini':
      return await callGemini(prompt, model, apiKey);
    case 'mixtral':
      return await callMixtral(prompt, model, apiKey);
    case 'ollama':
      return await callOllama(prompt, model);
    default:
      throw new Error('Unknown AI service');
  }
};
