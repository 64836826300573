import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarOutlined, PlayCircleOutlined, EditOutlined, DeleteOutlined, SettingOutlined, AudioOutlined, FolderOutlined, PauseCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Button, Table, Typography, message, Popconfirm, Card, List, Space } from 'antd';
import '../styles/Dashboard.css';
import FileUpload from './FileUpload';
import { useDarkMode } from '../App';
import { supabase, uploadAudioRecording, uploadTranscription, deleteRecording } from '../DatabaseService';

const { Title, Paragraph, Text } = Typography;

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
};

const RecordingCard = ({ recording, onEdit, onDelete, onPlay, isDeleting }) => {
  return (
    <Card
      size="small"
      className="recording-card"
      actions={[
        <PlayCircleOutlined key="play" onClick={() => onPlay(recording.id)} />,
        <EditOutlined key="edit" onClick={() => onEdit(recording.id)} />,
        <Popconfirm
          title="Are you sure you want to delete this recording?"
          onConfirm={() => onDelete(recording.id)}
          okText="Yes"
          cancelText="No"
          okButtonProps={{ loading: isDeleting }}
        >
          <DeleteOutlined key="delete" />
        </Popconfirm>,
      ]}
    >
      <Card.Meta
        title={<Text ellipsis>{recording.name}</Text>}
        description={
          <Space direction="vertical" size={0}>
            <Text type="secondary">{recording.dateCreated}</Text>
            <Text type="secondary">{recording.duration}</Text>
          </Space>
        }
      />
    </Card>
  );
};

const Dashboard = ({
  user,
  recordings,
  folders,
  isLoading,
  setRecordings,
  setError
}) => {
  const [showFileUpload, setShowFileUpload] = useState(false);
  const { isDarkMode } = useDarkMode();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recordingTime, setRecordingTime] = useState(0);
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const timerRef = useRef(null);
  const [deletingRecordingId, setDeletingRecordingId] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);

  const startTimer = useCallback(() => {
    if (timerRef.current === null) {
      timerRef.current = setInterval(() => {
        setRecordingTime((prevTime) => prevTime + 1);
      }, 1000);
    }
  }, []);

  const stopTimer = useCallback(() => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current);
      timerRef.current = null;
    }
  }, []);

  const saveToSupabase = useCallback(async (audioBlob) => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.error('User not authenticated');
        message.error('User not authenticated');
        return;
      }

      message.loading('Uploading audio...');

      console.log('Recording time before upload:', recordingTime);
      const recordingData = await uploadAudioRecording(user.id, audioBlob, recordingTime);

      console.log('Audio recording uploaded successfully:', recordingData);
      message.loading('Audio uploaded. Transcribing...');

      const formData = new FormData();
      formData.append('audio', audioBlob, 'audio.webm');

      const response = await fetch('http://localhost:5000/api/transcribe', {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Transcription failed: ${response.statusText}`);
      }

      const transcriptionData = await response.json();

      console.log('Transcription data received:', transcriptionData);
      
      const transcriptionResult = await uploadTranscription(user.id, JSON.stringify(transcriptionData), recordingData.id);

      if (transcriptionResult.success) {
        console.log('Transcription uploaded successfully:', transcriptionResult.data);
        message.success('Upload and transcription complete');
      } else {
        console.error('Error uploading transcription:', transcriptionResult.error);
        message.error(`Error uploading transcription: ${transcriptionResult.error}`);
      }

      // Refresh the recordings list
      // You might need to implement a function to fetch updated recordings
      // setRecordings(await fetchUpdatedRecordings());

    } catch (error) {
      console.error('Error in saveToSupabase:', error);
      message.error(`Error: ${error.message}`);
    }
  }, [recordingTime]);

  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      audioChunksRef.current = [];

      mediaRecorder.ondataavailable = (e) => {
        if (e.data.size > 0) {
          audioChunksRef.current.push(e.data);
        }
      };

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
        console.log('Audio recording finished.');
        
        try {
          await saveToSupabase(audioBlob);
        } catch (error) {
          console.error('Error saving to Supabase:', error);
          message.error(`Failed to upload: ${error.message}`);
        }
      };

      mediaRecorder.start();
      setIsRecording(true);
      setIsPaused(false);
      startTimer();
    } catch (error) {
      console.error('Error starting audio recording:', error);
      message.error(`Failed to start recording: ${error.message}`);
    }
  }, [saveToSupabase, startTimer]);

  const pauseRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.pause();
      setIsPaused(true);
      stopTimer();
    }
  }, [isRecording, stopTimer]);

  const resumeRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording && isPaused) {
      mediaRecorderRef.current.resume();
      setIsPaused(false);
      startTimer();
    }
  }, [isRecording, isPaused, startTimer]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      stopTimer();
      setIsRecording(false);
      setIsPaused(false);
      setRecordingTime(0);
    }
  }, [isRecording, stopTimer]);

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current);
      }
    };
  }, []);

  if (isLoading) {
    return <div className="dashboard-loading">Loading...</div>;
  }

  const handleFileUpload = (acceptedFiles) => {
    console.log('Uploaded files:', acceptedFiles);
    setShowFileUpload(false);
  };

  const handleEdit = (id) => {
    // Implement edit functionality
    message.info(`Editing recording ${id}`);
  };

  const handleDelete = async (id) => {
    try {
      setDeletingRecordingId(id);
      const recordingToDelete = recordings.find(recording => recording.id === id);
      const recordingName = recordingToDelete ? recordingToDelete.name : 'Unknown';
      
      console.log(`Attempting to delete recording: ${recordingName} (ID: ${id})`);
      const result = await deleteRecording(id);
      
      if (result.success) {
        setRecordings(recordings.filter(recording => recording.id !== id));
        message.success(`Recording "${recordingName}" deleted successfully`);
        console.log(`Successfully deleted recording: ${recordingName} (ID: ${id})`);
      } else {
        message.error(`Failed to delete recording "${recordingName}": ${result.error}`);
        console.error(`Failed to delete recording: ${recordingName} (ID: ${id})`, result.error);
      }
    } catch (error) {
      console.error(`Error deleting recording (ID: ${id}):`, error);
      message.error(`Error deleting recording: ${error.message}`);
    } finally {
      setDeletingRecordingId(null);
    }
  };

  const handleBulkDelete = async () => {
    if (selectedRowKeys.length === 0) {
      message.info('No recordings selected for deletion');
      return;
    }

    setIsBulkDeleting(true);
    let successCount = 0;
    let failCount = 0;

    for (const id of selectedRowKeys) {
      if (id) {  // Ensure the id is not undefined
        try {
          console.log(`Attempting to delete recording with ID: ${id}`);
          const result = await deleteRecording(id);
          if (result.success) {
            successCount++;
            console.log(`Successfully deleted recording with ID: ${id}`);
          } else {
            failCount++;
            console.error(`Failed to delete recording (ID: ${id}):`, result.error);
          }
        } catch (error) {
          failCount++;
          console.error(`Error deleting recording (ID: ${id}):`, error);
        }
      } else {
        console.error('Encountered undefined recording ID during bulk delete');
        failCount++;
      }
    }

    setRecordings(prevRecordings => prevRecordings.filter(recording => !selectedRowKeys.includes(recording.id)));
    setSelectedRowKeys([]);
    setIsBulkDeleting(false);

    if (successCount > 0) {
      message.success(`Successfully deleted ${successCount} recording(s)`);
    }
    if (failCount > 0) {
      message.error(`Failed to delete ${failCount} recording(s)`);
    }
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => (
        <div className="recording-name" onClick={() => openPlayerScreen(record.id)}>
          <PlayCircleOutlined /> {text}
        </div>
      )
    },
    {
      title: 'Duration',
      dataIndex: 'duration',
      key: 'duration'
    },
    {
      title: 'Creator',
      dataIndex: 'creator',
      key: 'creator'
    },
    {
      title: 'Date Created',
      dataIndex: 'dateCreated',
      key: 'dateCreated'
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space>
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record.id)}
          />
          <Popconfirm
            title="Are you sure you want to delete this recording?"
            onConfirm={() => handleDelete(record.id)}
            okText="Yes"
            cancelText="No"
            okButtonProps={{ loading: deletingRecordingId === record.id }}
          >
            <Button
              type="link"
              icon={<DeleteOutlined />}
              loading={deletingRecordingId === record.id}
            />
          </Popconfirm>
        </Space>
      )
    }
  ];

  const openPlayerScreen = (recordingId) => {
    console.log('Dashboard: Navigate to PlayerScreen with ID:', recordingId);
    navigate(`/player/${recordingId}`);
  };

  const openFileManager = () => {
    navigate('/file-manager');
  };

  const openSettings = () => {
    // Implement settings functionality
    message.info('Opening settings');
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  // Sort recordings by date, newest first
  const sortedRecordings = [...recordings].sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));

  // Limit to 5 recordings for mobile view
  const mobileRecordings = sortedRecordings.slice(0, 5);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      console.log('Selected row keys:', newSelectedRowKeys);
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };

  return (
    <div className={`dashboard ${isDarkMode ? 'dark' : ''}`}>
      <div className="dashboard-content">
        <section className="events-section">
          <Title level={3}>Today's events</Title>
          <Paragraph>
            Open your calendar (Google Calendar or Outlook) and create events.
          </Paragraph>
          <Card className="events-container">
            <div className="no-events">
              <CalendarOutlined className="calendar-icon" />
              <Paragraph className="no-events-text">No events today</Paragraph>
            </div>
            <Paragraph className="events-note">Events created in your calendar will be shown here.</Paragraph>
          </Card>
        </section>
        <section className="recordings-section">
          <div className="recordings-header">
            <Title level={4}>Recent Recordings</Title>
            <Button
              type="primary"
              danger
              onClick={handleBulkDelete}
              disabled={selectedRowKeys.length === 0}
              loading={isBulkDeleting}
            >
              Delete Selected ({selectedRowKeys.length})
            </Button>
          </div>
          {showFileUpload && (
            <div className="file-upload-container">
              <FileUpload onUpload={handleFileUpload} />
            </div>
          )}
          <div className="recordings-table-container">
            {isMobile ? (
              <List
                grid={{ gutter: 16, column: 1 }}
                dataSource={mobileRecordings}
                renderItem={(recording) => (
                  <List.Item>
                    <RecordingCard
                      recording={recording}
                      onEdit={handleEdit}
                      onDelete={handleDelete}
                      onPlay={openPlayerScreen}
                      isDeleting={deletingRecordingId === recording.id}
                    />
                  </List.Item>
                )}
              />
            ) : (
              <Table
                rowSelection={rowSelection}
                columns={columns}
                dataSource={sortedRecordings}
                rowKey="id"
                pagination={{ pageSize: 10 }}
                onRow={(record) => ({
                  onClick: () => openPlayerScreen(record.id)
                })}
                scroll={{ y: 'calc(100vh - 300px)' }}
              />
            )}
          </div>
        </section>
      </div>
      {isMobile && (
        <div className="mobile-controls">
          <Button type="text" icon={<SettingOutlined />} onClick={openSettings} />
          {!isRecording ? (
            <Button type="primary" shape="circle" icon={<AudioOutlined />} onClick={startRecording} />
          ) : (
            <>
              {isPaused ? (
                <Button type="primary" shape="circle" icon={<PlayCircleOutlined />} onClick={resumeRecording} />
              ) : (
                <Button type="primary" shape="circle" icon={<PauseCircleOutlined />} onClick={pauseRecording} />
              )}
              <Button type="primary" shape="circle" icon={<StopOutlined />} onClick={stopRecording} />
            </>
          )}
          <Button type="text" icon={<FolderOutlined />} onClick={openFileManager} />
        </div>
      )}
      {isRecording && (
        <div className="recording-indicator">
          Recording: {formatTime(recordingTime)}
        </div>
      )}
    </div>
  );
};

export default Dashboard;
