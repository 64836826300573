import React, { createContext, useState, useContext, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from './AuthContext';
import AppContent from './AppContent';
import LoginForm from './LoginForm';
import Layout from './components/Layout';
import LoadingSpinner from './components/LoadingSpinner';
import AudioControls from './components/AudioControls';

const DarkModeContext = createContext();

export const useDarkMode = () => useContext(DarkModeContext);

const DarkModeProvider = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const storedDarkMode = localStorage.getItem('darkMode');
    if (storedDarkMode !== null) {
      setIsDarkMode(JSON.parse(storedDarkMode));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(isDarkMode));
    document.body.classList.toggle('dark', isDarkMode);
  }, [isDarkMode]);

  const toggleDarkMode = () => {
    setIsDarkMode(prevMode => !prevMode);
  };

  return (
    <DarkModeContext.Provider value={{ isDarkMode, toggleDarkMode }}>
      {children}
    </DarkModeContext.Provider>
  );
};

const AuthenticatedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  
  if (loading) {
    return <LoadingSpinner />;
  }
  
  return user ? children : <Navigate to="/login" />;
};

const App = () => {
  const { user, signOut } = useAuth();
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [duration, setDuration] = useState(0);
  const mediaRecorder = useRef(null);
  const audioChunks = useRef([]);
  const timerRef = useRef(null);

  const handleRecordScreen = () => {
    // Implement screen recording logic
    console.log("Record screen");
  };

  const handleShowAudioRecording = async () => {
    if (!isRecording) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        mediaRecorder.current = new MediaRecorder(stream);
        
        mediaRecorder.current.ondataavailable = (event) => {
          audioChunks.current.push(event.data);
        };

        mediaRecorder.current.onstop = () => {
          const audioBlob = new Blob(audioChunks.current, { type: 'audio/wav' });
          const audioUrl = URL.createObjectURL(audioBlob);
          console.log("Audio recording completed. URL:", audioUrl);
          // You can implement further logic here, like saving the audio or displaying it to the user
        };

        mediaRecorder.current.start();
        setIsRecording(true);
        startTimer();
      } catch (error) {
        console.error("Error accessing microphone:", error);
      }
    } else {
      handleStopRecording();
    }
  };

  const handlePauseRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.pause();
      setIsPaused(true);
      clearInterval(timerRef.current);
    }
  };

  const handleResumeRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "paused") {
      mediaRecorder.current.resume();
      setIsPaused(false);
      startTimer();
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      mediaRecorder.current.stream.getTracks().forEach(track => track.stop());
      setIsRecording(false);
      setIsPaused(false);
      setDuration(0);
      clearInterval(timerRef.current);
      audioChunks.current = [];
    }
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setDuration(prev => prev + 1);
    }, 1000);
  };

  const handleImportFiles = () => {
    // Implement file import logic
    console.log("Import files");
  };

  const handleTranscribeMeeting = () => {
    // Implement meeting transcription logic
    console.log("Transcribe meeting");
  };

  const handleRecordVideo = () => {
    // Implement video recording logic
    console.log("Record video");
  };

  return (
    <AuthProvider>
      <DarkModeProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<LoginForm />} />
            <Route
              path="/*"
              element={
                <AuthenticatedRoute>
                  <Layout
                    user={user}
                    onSignOut={signOut}
                    onRecordScreen={handleRecordScreen}
                    onShowAudioRecording={handleShowAudioRecording}
                    onImportFiles={handleImportFiles}
                    onTranscribeMeeting={handleTranscribeMeeting}
                    onRecordVideo={handleRecordVideo}
                  >
                    <AppContent />
                    {isRecording && (
                      <AudioControls
                        isPaused={isPaused}
                        duration={duration}
                        onPause={handlePauseRecording}
                        onResume={handleResumeRecording}
                        onStop={handleStopRecording}
                      />
                    )}
                  </Layout>
                </AuthenticatedRoute>
              }
            />
          </Routes>
        </Router>
      </DarkModeProvider>
    </AuthProvider>
  );
};

export default App;
