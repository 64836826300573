import React, { useState, useEffect, useCallback, useRef } from 'react'
import { Button, message, Modal, Space, Tooltip, Typography } from 'antd'
import { PauseOutlined, CloseOutlined } from '@ant-design/icons'
import { motion } from 'framer-motion' // Import framer motion
import './index.css'
import {
  supabase,
  uploadAudioRecording,
  uploadTranscription
} from '../../DatabaseService'
import DefaultButton from '../ui/Buttons/defaultButton'
import { LuFileAudio } from 'react-icons/lu'
import PrimaryButton from '../ui/Buttons/primaryButton'
import { FiMic } from 'react-icons/fi'

const { Text } = Typography

const AudioRecorder = ({ username = 'John', secondary }) => {
  const [open, setOpen] = useState(false)

  const [isRecording, setIsRecording] = useState(false)
  const [isPaused, setIsPaused] = useState(false)
  const [recordingTime, setRecordingTime] = useState(0)
  const mediaRecorderRef = useRef(null)
  const audioChunksRef = useRef([])
  const timerRef = useRef(null)

  const getUserInitials = name => {
    const uname = name ? name : 'john'
    return uname.charAt(0).toUpperCase()
  }

  const handleOpen = () => {
    setOpen(true)
    startRecording()
  }

  const handleClose = () => {
    setOpen(false)
    stopRecording()
  }

  const startTimer = useCallback(() => {
    if (timerRef.current === null) {
      timerRef.current = setInterval(() => {
        setRecordingTime(prevTime => prevTime + 1)
      }, 1000)
    }
  }, [])

  const stopTimer = useCallback(() => {
    if (timerRef.current !== null) {
      clearInterval(timerRef.current)
      timerRef.current = null
    }
  }, [])

  //   const resetTimer = () => {
  //     setSecondsElapsed(0);
  //   };

  const formatTime = seconds => {
    const mins = Math.floor(seconds / 60)
    const secs = seconds % 60
    return `${mins < 10 ? '0' : ''}${mins}:${secs < 10 ? '0' : ''}${secs}`
  }

  // Framer Motion animation for audio effect
  const circleVariants = {
    recording: {
      scale: [1, 1.2, 1],
      opacity: [1, 0.5, 1],
      transition: {
        duration: 1,
        repeat: Infinity,
        ease: 'easeInOut'
      }
    },
    paused: {
      scale: 1,
      opacity: 1
    }
  }
  const saveToSupabase = useCallback(
    async audioBlob => {
      try {
        const {
          data: { user }
        } = await supabase.auth.getUser()
        if (!user) {
          console.error('User not authenticated')
          message.error('User not authenticated')
          return
        }

        message.loading('Uploading audio...')

        console.log('Recording time before upload:', recordingTime)
        const recordingData = await uploadAudioRecording(
          user.id,
          audioBlob,
          recordingTime
        )

        console.log('Audio recording uploaded successfully:', recordingData)
        message.loading('Audio uploaded. Transcribing...')

        const formData = new FormData()
        formData.append('audio', audioBlob, 'audio.webm')

        // const response = await fetch('http://localhost:5000/api/transcribe', {
        const response = await fetch('https://soloknows.com/api/transcribe', {
          method: 'POST',
          body: formData
        })

        if (!response.ok) {
          throw new Error(`Transcription failed: ${response.statusText}`)
        }

        const transcriptionData = await response.json()

        console.log('Transcription data received:', transcriptionData)

        const transcriptionResult = await uploadTranscription(
          user.id,
          JSON.stringify(transcriptionData),
          recordingData.id
        )

        if (transcriptionResult.success) {
          console.log(
            'Transcription uploaded successfully:',
            transcriptionResult.data
          )
          message.success('Upload and transcription complete')
        } else {
          console.error(
            'Error uploading transcription:',
            transcriptionResult.error
          )
          message.error(
            `Error uploading transcription: ${transcriptionResult.error}`
          )
        }

        // Refresh the recordings list
        // You might need to implement a function to fetch updated recordings
        // setRecordings(await fetchUpdatedRecordings());
      } catch (error) {
        console.error('Error in saveToSupabase:', error)
        message.error(`Error: ${error.message}`)
      }
    },
    [recordingTime]
  )
  const startRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      const mediaRecorder = new MediaRecorder(stream)
      mediaRecorderRef.current = mediaRecorder
      audioChunksRef.current = []

      mediaRecorder.ondataavailable = e => {
        if (e.data.size > 0) {
          audioChunksRef.current.push(e.data)
        }
      }

      mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(audioChunksRef.current, {
          type: 'audio/webm'
        })
        console.log('Audio recording finished.')

        try {
          await saveToSupabase(audioBlob)
        } catch (error) {
          console.error('Error saving to Supabase:', error)
          message.error(`Failed to upload: ${error.message}`)
        }
      }

      mediaRecorder.start()
      setIsRecording(true)
      setIsPaused(false)
      startTimer()
    } catch (error) {
      console.error('Error starting audio recording:', error)
      message.error(`Failed to start recording: ${error.message}`)
    }
  }, [saveToSupabase, startTimer])

  const pauseRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.pause()
      setIsPaused(true)
      stopTimer()
    }
  }, [isRecording, stopTimer])

  const resumeRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording && isPaused) {
      mediaRecorderRef.current.resume()
      setIsPaused(false)
      startTimer()
    }
  }, [isRecording, isPaused, startTimer])

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop()
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop())
      stopTimer()
      setIsRecording(false)
      setIsPaused(false)
      setRecordingTime(0)
      setOpen(false)
    }
  }, [isRecording, stopTimer])

  const cancelRecording = useCallback(() => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop()
      mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop())
      stopTimer()
      setIsRecording(false)
      setIsPaused(false)
      setRecordingTime(0)
      setOpen(false)
    }
  }, [isRecording, stopTimer])

  useEffect(() => {
    return () => {
      if (timerRef.current !== null) {
        clearInterval(timerRef.current)
      }
    }
  }, [])

  return (
    <>
      {/* <Tooltip title = {'audio'}> */}
      {secondary ? (
        <PrimaryButton shape='circle' onClick={handleOpen}>
          {
            <Tooltip title='Transcribe' style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
              <LuFileAudio style={{ color: '#ec2f6e' }} />
            </Tooltip>
          }
        </PrimaryButton>
      ) : (
        <DefaultButton shape={'circle'} onClick={handleOpen}>
          <Tooltip title='Transcribe' style={{ fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            <LuFileAudio />
          </Tooltip>
        </DefaultButton>
      )}
      {/* </Tooltip> */}
      <Modal
        className='custom-modal'
        title={<span style={{ color: 'var(--text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>Recording...</span>}
        open={open}
        onCancel={handleClose}
        footer={null}
        centered
        closeIcon={<CloseOutlined style={{ color: 'var(--text-color)' }} />}
        // bodyProps={}
        styles={{
          content: { backgroundColor: 'var(--bg-color)' },
          header: { backgroundColor: 'var(--bg-color)' },
          body: { backgroundColor: 'var(--bg-color)' }
        }}
      >
        {/* Circular div with user initials and audio effect */}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            marginBottom: 20,
            backgroundColor: 'var(--bg-color)'
          }}
        >
          {/* Outer animated pulse effect */}
          <motion.div
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
            animate={isRecording && !isPaused ? 'recording' : 'paused'}
            variants={circleVariants}
          >
            {/* Inner initials circle */}
            <div
              style={{
                width: 80,
                height: 80,
                borderRadius: '50%',
                backgroundColor: '#5684e6',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '2rem',
                color: 'white',
                zIndex: 2, 
                fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
              }}
            >
              {getUserInitials(username)}
            </div>
          </motion.div>

          {/* Timer display */}
          <Text style={{ fontSize: '1.2rem', color: 'var(--sec-text-color)', fontFamily: '"Roboto", "Helvetica Neue", sans-serif' }}>
            {formatTime(recordingTime)}
          </Text>
        </div>

        {/* Pause and Cancel buttons */}
        <div
          direction='horizontal'
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '60%',
            margin: 'auto'
          }}
        >
          {isPaused ? (
            <Button
              style={{
                margin: 0,
                backgroundColor: 'var(--primary-color)',
                color: 'white',
                border: 'none',
                fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
              }}
              onClick={resumeRecording}
              icon={<PauseOutlined />}
            >
              Resume
            </Button>
          ) : (
            <Button
              style={{
                margin: 0,
                backgroundColor: 'var(--primary-color)',
                color: 'var(--text-color)',
                border: 'none',
                fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
              }}
              onClick={pauseRecording}
              icon={<PauseOutlined />}
            >
              Pause
            </Button>
          )}
          <Button
            style={{
              margin: 0,
              backgroundColor: 'green',
              color: 'white',
              border: 'none',
              fontFamily: '"Roboto", "Helvetica Neue", sans-serif'
            }}
            danger
            onClick={stopRecording}
            icon={<CloseOutlined />}
          >
            Finish
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default AudioRecorder
