import React, { useState } from 'react'
import { Input, Tooltip } from 'antd'
import './index.css'
import AudioRecorder from '../AudioRecorder'
import PrimaryButton from '../ui/Buttons/primaryButton'
import DefaultButton from '../ui/Buttons/defaultButton'
import { FaArrowUp, FaMicrophone } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

const ChatComponent = () => {
  const [newMessage, setNewMessage] = useState('')
  const navigate = useNavigate()

  const handleSendMessage = () => {
    if (newMessage.trim() === '') return
    // Navigate to the AI chat page with a new chat session
    navigate(`/ai-chat?message=${encodeURIComponent(newMessage)}&newChat=true`)
    setNewMessage('')
  }

  const handleInputKeyPress = e => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSendMessage()
    }
  }

  return (
    <div className='chat-outer-container'>
      <div className='quick-chat-container'>
        <div className='quick-chat-input-container'>
          <Input.TextArea
            placeholder='Hello, how can I help you!'
            value={newMessage}
            onChange={e => setNewMessage(e.target.value)}
            className='quick-chat-input'
            autoSize={{ minRows: 1, maxRows: 3 }}
            onPressEnter={handleInputKeyPress}
          />
          <div className='chat-input-buttons'>
            <DefaultButton shape='circle'>
              <Tooltip title={'Record audio'}>
                <FaMicrophone />
              </Tooltip>
            </DefaultButton>
            <AudioRecorder />
            <PrimaryButton
              shape='circle'
              icon={<FaArrowUp />}
              onClick={handleSendMessage}
              disabled={newMessage?.trim() === ''}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatComponent
