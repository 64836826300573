import { useState, useRef, useCallback } from 'react';
import { uploadRecording } from '../DatabaseService';

export const useRecordingLogic = (user, setRecordings, setError) => {
  const [isRecording, setIsRecording] = useState(false);
  const [recordingType, setRecordingType] = useState('screen');
  const [isSelectingScreen, setIsSelectingScreen] = useState(false);
  const [selectedStream, setSelectedStream] = useState(null);
  const [showRecordingOptions, setShowRecordingOptions] = useState(false);
  const [recordingOptions, setRecordingOptions] = useState({
    screen: true,
    camera: false,
    audio: true
  });

  const mediaRecorderRef = useRef(null);
  const chunksRef = useRef([]);
  const startTimeRef = useRef(null);

  const toggleRecordingOptions = useCallback(() => {
    setShowRecordingOptions(prev => !prev);
  }, []);

  const handleRecordingStop = useCallback(async (metadata) => {
    try {
      const blob = new Blob(chunksRef.current, { type: 'audio/webm' }); // Always audio/webm
      const now = new Date();
      const fileName = `SoLoRecall_${(now.getMonth() + 1).toString().padStart(2, '0')}_${now.getDate().toString().padStart(2, '0')}_${now.getFullYear()}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}.webm`;
      const file = new File([blob], fileName, { type: 'audio/webm' });
  
      const newRecording = await uploadRecording(user.id, file, 'audio', metadata.duration, metadata);
      setRecordings(prev => [...prev, newRecording]);
    } catch (error) {
      console.error('Error in uploadRecording:', error);
      setError(`Failed to save recording: ${error.message}`);
    }
  }, [user.id, setRecordings, setError]);

  const startRecording = useCallback(async () => { // Make startRecording async
    if (!setError) {
      console.error("setError is not defined");
      return;
    }
    if (!selectedStream) {
      setError("Please select media to record first.");
      return;
    }
  
    mediaRecorderRef.current = new MediaRecorder(selectedStream);
  
    chunksRef.current = [];
    mediaRecorderRef.current.ondataavailable = (event) => {
      chunksRef.current.push(event.data);
    };
  
    mediaRecorderRef.current.onstop = async () => { 
      const duration = Math.round((Date.now() - startTimeRef.current) / 1000);
      await handleRecordingStop({ duration }); 
    };
  
    mediaRecorderRef.current.start();
    startTimeRef.current = Date.now();
    setIsRecording(true);
  }, [selectedStream, setError, handleRecordingStop]); 
  
  const startScreenSelection = useCallback(async (options = {}) => {
    if (!setError) {
      console.error("setError is not defined");
      return;
    }
    if (!user) {
      setError("User not authenticated. Please log in.");
      return;
    }

    try {
      setError(null);
      setIsSelectingScreen(true);

      let stream = new MediaStream();

      const videoOption = options.videoOption || 'screenOnly';
      const audioOption = options.audioOption || 'micAudio';

      if (videoOption === 'screenOnly' || videoOption === 'screenAndCamera') {
        const screenStream = await navigator.mediaDevices.getDisplayMedia({ video: true, audio: audioOption !== 'micAudio' });
        screenStream.getTracks().forEach(track => stream.addTrack(track));
      }

      if (videoOption === 'cameraOnly' || videoOption === 'screenAndCamera') {
        const cameraStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
        cameraStream.getVideoTracks().forEach(track => stream.addTrack(track));
      }

      if (audioOption === 'micAudio' || audioOption === 'both') {
        const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        audioStream.getAudioTracks().forEach(track => stream.addTrack(track));
      }

      setSelectedStream(stream);
      setIsSelectingScreen(false);
      setShowRecordingOptions(false);
      startRecording();
    } catch (err) {
      console.error("Error selecting media:", err);
      setError("Failed to select media. Please try again.");
      setIsSelectingScreen(false);
    }
  }, [user, setError, startRecording]);

  const stopRecording = useCallback(() => {
    if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
    if (selectedStream) {
      selectedStream.getTracks().forEach(track => track.stop());
      setSelectedStream(null);
    }
  }, [selectedStream]);

  return {
    isRecording,
    recordingType,
    isSelectingScreen,
    selectedStream,
    startScreenSelection,
    startRecording,
    stopRecording,
    handleRecordingComplete: handleRecordingStop,
    showRecordingOptions,
    setShowRecordingOptions,
    toggleRecordingOptions,
    recordingOptions,
    setRecordingOptions
  };
};

export default useRecordingLogic;
