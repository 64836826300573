import { createClient } from '@supabase/supabase-js';

console.log('Environment variables:', process.env);
console.log('REACT_APP_SUPABASE_URL:', process.env.REACT_APP_SUPABASE_URL);
console.log('REACT_APP_SUPABASE_ANON_KEY:', process.env.REACT_APP_SUPABASE_ANON_KEY);

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY;

if (!supabaseUrl) {
  throw new Error('Supabase URL is missing. Check your .env file for REACT_APP_SUPABASE_URL.');
}

if (!supabaseAnonKey) {
  throw new Error('Supabase anonymous key is missing. Check your .env file for REACT_APP_SUPABASE_ANON_KEY.');
}

const supabase = createClient(supabaseUrl, supabaseAnonKey);

export { supabase };

export const signUp = async (email, password, firstName, lastName) => {
  console.log('Attempting to sign up with:', { email, firstName, lastName });
  try {
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          first_name: firstName,
          last_name: lastName,
        }
      }
    });
    if (error) throw error;

    console.log('Sign up successful:', data);

    // Create a folder for the new user in Supabase storage
    const { user } = data;
    console.log('Creating user folder in storage for user:', user.id);
    const { error: storageError } = await supabase.storage
      .from('recordings')
      .upload(`${user.id}/.keep`, new Blob(['']), {
        cacheControl: '3600',
        upsert: false
      });

    if (storageError) throw storageError;

    console.log('User folder created successfully');
    return data;
  } catch (error) {
    console.error('Error during sign up process:', error);
    throw error;
  }
};

export const signIn = async (email, password) => {
  console.log('Attempting to sign in with:', email);
  const { data, error } = await supabase.auth.signInWithPassword({ email, password });
  if (error) {
    console.error('Sign in error:', error);
    throw error;
  }
  console.log('Sign in successful:', data);
};

export const signInWithGoogle = async () => {
  console.log('Attempting to sign in with Google');
  const { data, error } = await supabase.auth.signInWithOAuth({
    provider: 'google'
  });
  if (error) {
    console.error('Google sign in error:', error);
    throw error;
  }
  console.log('Google sign in successful:', data);
};

export const signOut = async () => {
  console.log('Attempting to sign out');
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.error('Sign out error:', error);
    throw error;
  }
  console.log('Sign out successful');
};

export const getCurrentUser = async () => {
  console.log('Fetching current user');
  const { data: { user }, error } = await supabase.auth.getUser();
  if (error) {
    console.error('Error fetching current user:', error);
    throw error;
  }
  console.log('Current user:', user);
  return user;
};

export const onAuthStateChange = (callback) => {
  console.log('Setting up auth state change listener');
  return supabase.auth.onAuthStateChange((event, session) => {
    console.log('Auth state changed:', event, session);
    callback(event, session);
  });
};
