import React, { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FiBarChart2, FiFileText, FiCalendar, FiClock, FiSmartphone, FiTrash2, FiLogOut, FiSearch, FiStar, FiMessageSquare, FiHelpCircle, FiGift, FiSettings, FiMic, FiUpload, FiVideo, FiMenu, FiMoon, FiSun } from 'react-icons/fi';
import solorecallLogo from '../components/img/solorecall_logo.png';
import '../styles/Sidebar.css';
import { useDarkMode } from '../App';

const Sidebar = ({ user, onSignOut, onRecordScreen, onShowAudioRecording, onImportFiles, onTranscribeMeeting, onRecordVideo, onSidebarToggle }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [sidebarOpen, setSidebarOpen] = useState(!isMobile);
  const { isDarkMode, toggleDarkMode } = useDarkMode();
  const sidebarRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      const mobile = window.innerWidth <= 768;
      setIsMobile(mobile);
      setSidebarOpen(!mobile);
    };

    const handleClickOutside = (event) => {
      if (isMobile && sidebarOpen && sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setSidebarOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobile, sidebarOpen]);

  useEffect(() => {
    onSidebarToggle(sidebarOpen);
  }, [sidebarOpen, onSidebarToggle]);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const sidebarItems = [
    { icon: <FiBarChart2 />, text: 'Dashboard', path: '/dashboard' },
    { icon: <FiFileText />, text: 'All Files', path: '/files' },
    { icon: <FiCalendar />, text: 'Upcoming Meeting Events', path: '/upcoming-events' },
    { icon: <FiClock />, text: 'Scheduler', path: '/scheduler' },
    { icon: <FiSmartphone />, text: 'My Devices', path: '/devices' },
    { icon: <FiTrash2 />, text: 'Trash', path: '/trash' },
  ];

  const actionItems = [
    { icon: <FiVideo />, text: "Record your screen", onClick: onRecordScreen },
    { icon: <FiMic />, text: "Record an audio", onClick: onShowAudioRecording },
    { icon: <FiUpload />, text: "Import files", onClick: onImportFiles },
    { icon: <FiMessageSquare />, text: "Transcribe Live Meetings", onClick: onTranscribeMeeting },
    { icon: <FiVideo />, text: "Record a video", onClick: onRecordVideo },
    { icon: <FiMessageSquare />, text: "Chat with AI", onClick: () => navigate('/ai-chat') },
  ];

  const bottomItems = [
    { icon: <FiStar />, text: 'Showcase', path: '/showcase', isNew: true },
    { icon: <FiMessageSquare />, text: 'Contact Support', path: '/support' },
    { icon: <FiHelpCircle />, text: 'Submit an Idea', path: '/submit-idea' },
    { icon: <FiGift />, text: 'Refer & Earn', path: '/refer' },
    { icon: <FiSettings />, text: 'Settings', path: '/settings' },
  ];

  return (
    <>
      {isMobile && (
        <button className="sidebar-toggle" onClick={toggleSidebar} aria-label="Toggle Sidebar">
          <FiMenu />
        </button>
      )}
      <aside ref={sidebarRef} className={`sidebar ${sidebarOpen ? 'open' : ''} ${isDarkMode ? 'dark' : ''}`}>
        <div className="sidebar-logo">
          <img src={solorecallLogo} alt="SoloRecall Logo" />
        </div>

        {user && user.email && (
          <div className="sidebar-user">
            <div className="sidebar-user-avatar" aria-hidden="true">
              {user.email.charAt(0).toUpperCase()}
            </div>
            <div className="sidebar-user-info">
              <p className="sidebar-user-name">{user.email.split('@')[0]}'s Workspace</p>
              <p className="sidebar-user-email">{user.email}</p>
            </div>
          </div>
        )}

        <button onClick={onSignOut} className="sidebar-button">
          <FiLogOut />
          <span>Sign Out</span>
        </button>

        <div className="sidebar-search">
          <div className="sidebar-search-container">
            <FiSearch className="sidebar-search-icon" aria-hidden="true" />
            <input type="text" placeholder="Quick Find" className="sidebar-search-input" aria-label="Quick Find" />
            <span className="sidebar-search-shortcut" aria-hidden="true">Ctrl K</span>
          </div>
        </div>

        <nav className="sidebar-nav">
          {sidebarItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className={`sidebar-nav-item ${location.pathname === item.path ? 'active' : ''}`}
              onClick={() => isMobile && toggleSidebar()}
            >
              <span className="sidebar-nav-item-content">
                {item.icon}
                <span>{item.text}</span>
              </span>
            </Link>
          ))}
        </nav>

        <div className="sidebar-actions">
          <h2 className="sidebar-actions-title">Quick Actions</h2>
          {actionItems.map((action, index) => (
            <button
              key={index}
              onClick={() => {
                action.onClick();
                isMobile && toggleSidebar();
              }}
              className="sidebar-action-button"
            >
              {action.icon}
              <span>{action.text}</span>
            </button>
          ))}
        </div>

        <div className="sidebar-bottom">
          {bottomItems.map((item, index) => (
            <Link
              key={index}
              to={item.path}
              className="sidebar-bottom-item"
              onClick={() => {
                if (isMobile) {
                  toggleSidebar();
                }
              }}
            >
              {item.icon}
              <span>{item.text}</span>
              {item.isNew && (
                <span className="sidebar-new-badge">
                  NEW
                </span>
              )}
            </Link>
          ))}
          <button className="dark-mode-toggle" onClick={toggleDarkMode} aria-label="Toggle Dark Mode">
            {isDarkMode ? <FiSun /> : <FiMoon />}
            <span>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</span>
          </button>
        </div>
      </aside>
      {isMobile && sidebarOpen && (
        <div className="sidebar-overlay" onClick={toggleSidebar} />
      )}
    </>
  );
};

export default Sidebar;
