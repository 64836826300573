import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiUpload, FiX } from 'react-icons/fi';

const FileUpload = ({ onUpload, onClose }) => {
  const [selectedFile, setSelectedFile] = useState(null);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles.length > 0) {
      setSelectedFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'audio/*,video/*',
    multiple: false
  });

  const handleUpload = () => {
    if (selectedFile) {
      onUpload(selectedFile);
    }
  };

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
      <div className="relative bg-white dark:bg-gray-800 rounded-lg p-8 m-4 max-w-xl w-full">
        <button onClick={onClose} className="absolute top-4 right-4 text-gray-500 hover:text-gray-700">
          <FiX size={24} />
        </button>
        <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">Import Files</h2>
        <div {...getRootProps()} className="border-2 border-dashed border-gray-300 rounded-lg p-8 mb-4 cursor-pointer">
          <input {...getInputProps()} />
          {isDragActive ? (
            <p className="text-center text-gray-500">Drop the files here ...</p>
          ) : (
            <p className="text-center text-gray-500">Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>
        {selectedFile && (
          <div className="mb-4">
            <p className="text-sm text-gray-600 dark:text-gray-300">Selected file: {selectedFile.name}</p>
          </div>
        )}
        <div className="flex justify-end">
          <button
            onClick={handleUpload}
            disabled={!selectedFile}
            className={`flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg ${
              !selectedFile ? 'opacity-50 cursor-not-allowed' : 'hover:bg-blue-600'
            }`}
          >
            <FiUpload className="mr-2" />
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileUpload;